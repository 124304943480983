import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Student Landing page/Navbar'
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo'
import Hero from '../Student Landing page/Hero'
import Slider from '../Student Landing page/Slider'
import WhySmart from '../Student Landing page/WhySmart'
import Child_exp_sec from './Child_exp_sec'
import Unleash_sec from './Unleash_sec'
import { useHistory } from 'react-router-dom'

const NearestAbacusClass = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const History = useHistory();
 
  useEffect(() => {
    // Get the full URL
    const url = window.location.href;

    // Extract the city part from the URL
    const cityPart = url.split('abacus-classes-in-')[1]; 
    const city = cityPart
    // Make the API call
    const fetchData = async () => {
      try {
        const response = await fetch(`https://tt.mastermindschool.co.in/api/get_location_detail?location=${city}`);
        const result = await response.json();
        // console.log('api '+  result.data)
        if(result.message === "Valid page"){
        setData(result.data);
        setLoading(false)
        }else{
          History.push('/')
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if(loading){
    return(
      null
    )
  }
  return (
    <React.Fragment>
      <Helmet>
        { /* Standard metadata tags */}
        <title>{data.m_title}</title>
        <meta name="keywords" content={data.m_keyword}/>
        <meta name='description' content={data.m_description} />
        <meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>

      </Helmet>
      <Navbar />

      <Hero />

      <Unleash_sec H1={data.h1} h1dec={data.h1_description} H2={data.h2} h2dec={data.h2_description}/>

      <WhySmart />

      <Slider Heading='Students In Action'/>

       <Child_exp_sec/>

      <FooterStyleTwo
        footerTopClass='footer-top no-gap'
      />

    </React.Fragment>
  )
}

export default NearestAbacusClass