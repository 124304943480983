import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import HomeSixMain from './HomeSixMain';

import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import RefundPolicyMain from './RefundPolicyMain';

const RefundPolicy = () => {
    return (
        <React.Fragment>
            <Helmet>
            <title>Cancellation Refund Policy - Mastermind Abacus</title> 
				 <meta name="robots" content="noindex,nofollow" />
		
				<meta name="keywords" content=""/>
		<meta name="description" content="Detailed information about the Refund and Cancellation policy of the Company as appiicable once a subscription has been made on the website by a user."/>
		
		
		
		<meta property="og:title" content="Cancellation Refund Policy - Mastermind Abacus"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/cancellation-refund-policy"/>
		<meta property="og:description" content="Detailed information about the Refund and Cancellation policy of the Company as appiicable once a subscription has been made on the website by a user."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Cancellation Refund Policy - Mastermind Abacus"/>
		<meta name="twitter:description" content="Detailed information about the Refund and Cancellation policy of the Company as appiicable once a subscription has been made on the website by a user."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='home'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            <RefundPolicyMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default RefundPolicy;