import React from 'react';
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Tilty from 'react-tilty';
import AOS from 'aos';
import dots from '../../assets/img/banner/banner-dots.png';
import teacherImg from '../../assets/img/abacus/abacusMath/abacus-math-for-kids.webp';



const BannerMath = () => {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner BannerMath" >
                <div className="container relative">
                    <div className="col relative">
                        <div className="row-lg-5">
                           
                            <div className="banner-content pb-14">
                                <div className="banner-title family title-change" data-aos="fade-right" data-aos-duration="1000" >IMPROVE YOUR CHILD's... <br/>
                                MENTAL ARITHMETIC ABILITIES
                                   </div>
                                <div className="desc black-color mb-25" >YOUR CHILD CAN NOW BEAT THE CALCULATOR</div>
                              
                            </div>
                            
                        </div>
                    </div>

                    <div className='col-lg-5 teacher-image'>
                        <img 
                        src={teacherImg}
                        alt='Mastermind Abacus'
                        />
                    </div>
                    <div className="banner-dots-shape">
                        <Tilty>
                            <img
                                src={dots}
                                alt="Mastermind Abacus"
                            />
                        </Tilty>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default BannerMath;










