import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import HomeSixMain from './HomeSixMain';

import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import AboutMain from './AboutMain';

const About = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Abacus Company | Mastermind Abacus</title> 
				<meta name="keywords" content="Abacus Franchise, Abacus Franchisee, Abacus Franchisor Abacus, Abacus Books, Abacus Worksheets, Abacus Practice Sheet, Abacus Training, Abacus Online Training, Franchisee Opportunity"/>
		<meta name="description" content="Mastermind Abacus a division of Mastermind Tutorials Pvt. Ltd., deals in children supplementary education through 2000+ partners across 16 countries."/>
		
		
		
		<meta property="og:title" content="Abacus Company | Mastermind Abacus"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/about-us"/>
		<meta property="og:description" content="Mastermind Abacus a division of Mastermind Tutorials Pvt. Ltd., deals in children supplementary education through 2000+ partners across 16 countries."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Abacus Company | Mastermind Abacus"/>
		<meta name="twitter:description" content="Mastermind Abacus a division of Mastermind Tutorials Pvt. Ltd., deals in children supplementary education through 2000+ partners across 16 countries."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='about'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            <AboutMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default About;