import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BlogDetails from './BlogDetails';
// import SinglePostSidebar from './SinglePostSidebar';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import stickyLogo from '../../assets/img/logo/logo.png';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ErrorContent from '../../components/Error';
import RecentPost from '../../components/Widget/RecentPost';
import MetaWidget from '../../components/Widget/MetaWidget';
import Relatedblogs from '../../components/Widget/Relatedblogs';

const SinglePostRightSidebar = () => {
    const [Blog_detailData, setBlog_detailData] = useState('');
    const [paragraphs, setparagraphs] = useState('');
    const [error, setError] = useState(null);

    const { blogname } = useParams(); 
    // console.log(blogname);
    const IdBeforeSlash = blogname.split("/")[0];
    // console.log(IdBeforeSlash);

    const Handle_Blog_detail_API = async () => {
        try {
            const response = await fetch(`https://tt.mastermindschool.co.in/api/abacus_blog_detail?blog_id=${IdBeforeSlash}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            // console.log(result.data)
            setBlog_detailData(result.data)
            // const paragraph = result.data.blog_detail.shot_content.split('\n').filter(paragraph => paragraph.trim() !== '');
            // setparagraphs(paragraph)
        } catch (error) {
            setError(error)
        }
    };


    useEffect(() => {
        Handle_Blog_detail_API();
    }, [blogname]);

    if (error) {
        return <div>
            Error: {error.message}
            <ErrorContent />
        </div>;
    }

    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Struggling with Math? Unlock Math Joy with the Abacus!</title> 
				<meta name="keywords" content="math classes, stem education, math online classes, learning abacus, "/>
		<meta name="description" content="Is your child struggling with math? Discover the ancient abacus tool that makes learning fun! Mastermind Abacus' engaging online programs build."/>
		
		
		
		<meta property="og:title" content="Struggling with Math? Unlock Math Joy with the Abacus!"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/blog_detail/428/struggling-with-math-unlock-math-joy-with-the-abacus"/>
		<meta property="og:description" content="Is your child struggling with math? Discover the ancient abacus tool that makes learning fun! Mastermind Abacus' engaging online programs build."/>
	
					<meta property="og:type" content="blog"/>
		    <meta property="og:image:height" content="314"/>
		    <meta property="og:image:width" content="720"/>
    		<meta property="og:image" content="https://www.mmtplonline.com/blog_images/Struggling_with_Math_Unlock_Math_Joy_with_the_Abacus!.jpg"/>
    			<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Struggling with Math? Unlock Math Joy with the Abacus!"/>
		<meta name="twitter:description" content="Is your child struggling with math? Discover the ancient abacus tool that makes learning fun! Mastermind Abacus' engaging online programs build."/>
				<meta name="twitter:image" content="https://www.mmtplonline.com/blog_images/Struggling_with_Math_Unlock_Math_Joy_with_the_Abacus!.jpg"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='home'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />

            <SiteBreadcrumb
                pageTitle="Blog"
                pageName="Blog"
                breadcrumbsImg={bannerbg}
                status="1"
            />

            {/* Blog Details Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-8 pr-50 md-pr-14">
                            <BlogDetails BlogDetailData={Blog_detailData}  />
                        </div>
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <RecentPost resentBlogs={Blog_detailData.resent_blogs}/>
                                {Blog_detailData.related_blogs ? <Relatedblogs Relatedblog={Blog_detailData.related_blogs}/>: null} 
                                <MetaWidget archivedBlogs ={Blog_detailData.archived_blogs}/>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            {/* Blog Details End */}


            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    );
}


export default SinglePostRightSidebar;



