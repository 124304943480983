import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
// import RefundConent from './RefundContent';
import DeliverContent from './DeliveryContent';


const DeliveryMain = () => {

	return (
		<React.Fragment>

            <DeliverContent/>
			
			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default DeliveryMain;