import React from 'react'
import bannerbg from '../../assets/img/abacus/map.webp'
// import SectionTitle from '../../components/Common/SectionTitle'

const MapSection = () => {
  return (
    <React.Fragment>


      <div className=' rs-newsletter mapSection mt-30'>
        <div className=" sm-mb-30 mb-20 text-center">
          {/* <SectionTitle
            sectionClass="sec-title"
            titleClass="title mb-0"

            title={<>OUR TRAINING HAS BENEFITTED STUDENTS  <span style={{ color: "#fd6c24" }}> ACROSS 17 COUNTRIES </span> </>}
          /> */}

          <div className="sec-title ">
            <h2 className="title family mb-0">
            OUR TRAINING HAS BENEFITTED STUDENTS  <span className='title-change'> ACROSS 17 COUNTRIES </span>
            </h2>
            <div className="desc ">

            </div>
          </div>
        </div>
        <div className='mapImg text-center d-flex justify-content-center'>
          <img className='imgcl' src={bannerbg} alt='Mastermind Abacus' />
        </div>

      </div>


      {/* <div className=''>
        <img />
      </div> */}
    </React.Fragment>
  )
}

export default MapSection