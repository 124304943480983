import React from 'react';
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Tilty from 'react-tilty';
import AOS from 'aos';
import dots from '../../assets/img/banner/banner-dots.png';
import teacherImg from '../../assets/img/abacus/Research/abacus-images.png';



const BannerResearch = () => {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner BannerResearch style4">
                <div className="container relative">
                    <div className="col relative">
                        <div className="row-lg-6">
                           
                            <div className="banner-content pb-14 family offset-lg-4">
                                <div className="banner-title white-color"  >YEARS OF RESEARCH BACKED BY FACTS</div>
                                <div className="banner-title white-color"  >CONFIRMS THE ROLE OF ABACUS AS A HOLISTIC</div>
                                <div className="banner-title white-color">BRAIN DEVELOPMENT TOOL IN KIDS</div>
                            </div>
                          
                        </div>
                    </div>

                    <div className='col-lg-6 teacher-image'>
                        <img 
                        src={teacherImg}
                        alt='Mastermid Abacus'
                        />
                    </div>
                    <div className="banner-dots-shape">
                        <Tilty>
                            <img
                                src={dots}
                                alt='Mastermid Abacus'
                            />
                        </Tilty>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default BannerResearch;