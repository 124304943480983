import React from 'react';
import bgImg from '../../assets/img/abacus/History/histry-of-abacus.jpg';



const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    'min-hieght' : '280px',
    'background-size': 'cover',
    'background-position': 'center',
}



const BannerHistory = () => {
    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner style6" style={bgStyle}>
                <div className="container">
                    <div className="banner-content text-center family pt-50">
                        <div className="banner-title white-color" >A JOURNEY OF 3000 YEARS OF DEVELOPMENT</div>
                        <div className="banner-title white-color">AND TRANSFORMATION FROM A MERE CALCULATING INSTRUMENT TO</div>
                        <div className="banner-title white-color" >A JOURNEY OF 3000 YEARS OF DEVELOPMENT</div>
                       
                    </div>
                   
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default BannerHistory;