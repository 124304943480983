import React from 'react';
// import Team from './TeamSection';
// import Blog from './BlogSection';
// import AboutVideo from './VideoSection';
// import AboutCounter from './CounterSection';
// import Testimonial from './TestimonialSection';
// import ScrollToTop from '../../components/Common/ScrollTop';
import HistorySection from './HistorySection';
import BannerHistory from './HistoryBanner';
import ScrollToTop from '../../components/Common/ScrollTop';


const HistoryMain = () => {

    return (
        <React.Fragment>
            
            <BannerHistory/>
            <HistorySection/>

            <ScrollToTop status={'bookclass'} />
            
        </React.Fragment>
    )
}

export default HistoryMain;