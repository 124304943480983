import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import Tilty from 'react-tilty';
import SectionTitle from '../../components/Common/SectionTitle';
import axios from "axios";
import { HomeModal } from "../../components/Common/Modal/HomeModal";

// import animateBall from '../../assets/img/about/image-center-circle.png';
// import mainImage from '../../assets/img/abacus/lingo-bingo/Lingo-Bingo1.webp';

function HistorySection() {
    const [F_investmentData, setF_investmentData] = useState('')
    const [tab1, settab1] = useState(true);
    const [tab2, settab2] = useState(false);
    const [tab3, settab3] = useState(false);
    const [tab4, settab4] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    
    

    const getIP = async () => {
        const res = await axios.get("https://api.bigdatacloud.net/data/client-ip");
        getData(res.data.ipString)
    };

    const getData = async (ip) => {
        try {
            const res = await axios.get(`https://tt.mastermindschool.co.in/api/get_cournty_ip?ip=${ip}`);
            console.log(res.data.data)
            // fetchTime(res.data.data);
            get_fanchise_investment(res.data.data)
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };
    const get_fanchise_investment = async (value) => {
        try {
            const res = await axios.get(`https://tt.mastermindschool.co.in/api/get_fanchise_investment?country=${value}`)
            setF_investmentData(res.data.data)
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    }

    const renderContent = (value) => {
        switch (value) {
            case '1':
                settab1(true);
                settab2(false);
                settab3(false);
                settab4(false);
                break;
            case '2':
                settab1(false);
                settab2(true);
                settab3(false);
                settab4(false);
                break;
            case '3':
                settab1(false);
                settab2(false);
                settab3(true);
                settab4(false);
                break;
            case '4':
                settab1(false);
                settab2(false);
                settab3(false);
                settab4(true);
                break;
            default:
                return null;
        }
    };
    


    useEffect(() => {
        getIP();
        AOS.init();
    },[])

    return (
        <div id="rs-about" className="rs-about  history">
            <div className="container">
                <div className=" ">


                    <div className="text-center">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title mb-6 pt-0"
                            subtitleClass="sub-title primary"
                            // subtitle="About Us"
                            titleClass="title"
                            title={<>Abacus Franchise Cost</>}

                        />


                        <div className="sec-title mb-6 section-shadow" >
                            <div className="title1 black-color">Franchisee Fee {F_investmentData}</div>
                        </div>


                        <div className="sec-title mb-50 mt-50 section-shadow" >
                            <div className="title2 black-color">Franchisee Fee Inclusions</div>

                            <div className="fee" >
                                <div onClick={() => renderContent('1')} className="black-color font-bold fee-item" >
                                    Center License
                                </div>
                                {/* <div onClick={() => renderContent('2')} className="black-color font-bold " style={{ padding: "8px 15px", boxShadow: " 0px 1px 5px black", textShadow: " 0px 1px 3px gray", backgroundColor: "", width: "200px", marginInline: "6px" }}>
                                    Live Online Platform
                                </div> */}
                                <div onClick={() => renderContent('3')} className="black-color font-bold fee-item">
                                    Training
                                </div>
                                <div onClick={() => renderContent('4')} className="black-color font-bold fee-item" >
                                    Franchisee Kit
                                </div>

                            </div>

                            {tab1 ? <div className="sec-title pb-60 ">
                                <div className="title3 black-color">You will be authorized to start a center of your own in your territory.</div>
                            </div>:null}
                           
                            {tab3 ? <div className="sec-title mb-30 mt-30" >
                                <div className="title3 black-color">
                                    <div className="row ml-30 md-ml-0  mb-30 mt-30" >
                                        <div className="academic col-lg-5 col-sm-12 mb-30">
                                            <ul>
                                                <h4 className="text-center">ACADEMIC TRAINING & DURATION</h4>
                                                <li><i class="fa-solid fa-circle-chevron-right title-change mr-5 ml-5"></i>There are total 8 levels of training.</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training of 6 days/level for 3 hours per day.</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change" ></i>Teacher can start classes after completing 1st level Training.</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change" ></i>Next levels trainings can be taken at the intervals of 45 days</li>
                                            </ul>
                                        </div>
                                        <div className="academic col-lg-5 col-sm-12 mb-30">
                                            <ul>
                                            <h4 className="text-center">OTHER TRAININGS</h4>

                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training for Counseling</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training for Inquiry Handling</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training for Conducting Demo</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training for Marketing</li>
                                                <li><i class="fa-solid fa-circle-chevron-right mr-5 ml-5 title-change"></i>Training for ERP</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>:null}
                            {tab4 ? <div className="sec-title pb-60">
                                <div className="title3 black-color">You will also be provided with all materials required to kick start your center with an exclusive 'Training Kit' for the academic training of teachers.</div>
                            </div>:null}

                        </div>
                        {/* Section Title End */}
                        <div className="btn-part pb-50" >
                            <div className="readon2 popup-videos" onClick={openModal} >Franchisee Inquiry Form</div>
                        </div>
                    </div>

                </div>

            </div>
            <HomeModal  isOpen={isModalOpen} onClose={openModal} status={'FRANCHISE'}  mess={"FRANCHISE INQUIRY"}  id={'3'}  />
        </div>
    );
}

export default HistorySection;