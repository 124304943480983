import React from 'react';
import Blog from './BlogSection';
import About from './AboutSection';
import Courses from './CourseSection';
import Service from './ServiceSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerStyleThree from '../../components/Banner/BannerStyleThree';
import NewsletterThree from '../../components/Common/Newsletter/NewsletterThree';
import AboutSection02 from './AboutSection02';
import BenefiteSection from './BenefiteSection';
import OnlineClassSection from './OnlineClassSection';
import PracticeSection from './PracticeSection';
import GallerySection from './GallerySection';
import Faq from '../faq';
import Testimonial from './TestimonialSection';
import MapSection from './MapSection';
import RredLineSection from './RredLineSection';
import ChatBox from '../../components/Common/ScrollTop/ChatBox';

const HomeSixMain = () => {

	return (
		<React.Fragment >
			{/* Banner-area-start */}
			{/* <Suspense fallback={<div className='text-center' style={{height:"380px"} }>Loading...</div>}> */}
			<BannerStyleThree />

			{/* Banner-area-end */}


			<RredLineSection />

			{/* About-area-start */}
			<About />
			{/* About-area-end */}

			{/* About-area-start */}
			<AboutSection02 />
			{/* About-area-end */}



			{/* Courses-area-start */}
			<Courses />
			{/* Courses-area-end */}

			{/* Service-area-start */}
			<Service />
			{/* Service-area-end */}



			<BenefiteSection />


			<OnlineClassSection />

			<PracticeSection />



			<GallerySection />

			{/* Gallery-area-end */}


			{/* Testimonial-area-start */}
			<Testimonial />
			{/* Testimonial-area-end */}

			<Faq />

			{/* blog-area-start */}
			<Blog />
			{/* blog-area-end */}



			<MapSection />

			<NewsletterThree/>
			<ChatBox/>
			{/* scrolltop-start */}
			<ScrollToTop status={'bookclass'} />
			{/* scrolltop-end */}
			{/* </Suspense> */}

		</React.Fragment>
	);
}

export default HomeSixMain;