import React from 'react';
import HistorySection from './HistorySection';
import BannerHistory from './InvestmentBanner';


const InvestmentMain = () => {

    return (
        <React.Fragment>
            
            <BannerHistory/>
            <HistorySection/>

        </React.Fragment>
    )
}

export default InvestmentMain;