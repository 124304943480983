import React from 'react';
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Tilty from 'react-tilty';
import AOS from 'aos';
import circle from '../../../../assets/img/banner/banner-circle.png';
import dots from '../../../../assets/img/banner/banner-dots.png';
import bgImg from '../../../../assets/img/abacus/fran_bnr.png';

const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    'background-size': 'cover',
    'background-position': 'center',
    'height': '71vh',
    'display': 'grid',
    'align-items': 'center',
    "position" : 'reletive',
    // 'opacity':'0.5',
    // 'z-index' : '-1'
}



const Successfully = () => {

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner style4 " style={bgStyle}>
                <div className="container relative" >
                    <div className="row relative">
                        <div className="col-lg-6">
                            <div className="banner-content pb-14">
                                <div className="desc white-color mb-25 font-bold " data-aos="fade-left" data-aos-duration="1000" data-aos-delay="400" style={{ marginTop: "-10px" }}>
                                YOU HAVE SUCCESSFULLY REGISTERED, WE'LL CONTACT YOU SOON FOR FURTHER DETAILS.
                                </div>

                                {/* <div className="banner-btn z-index-1" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="600" style={{ marginTop: "20px" }}>
                                    <Link  className="readon2 banner-style" to="/abacus-franchise">Back to Home</Link>
                                </div> */}
                            </div>
                            <div className="banner-circle-shape">
                                <Tilty>
                                    <img
                                        className="rotateme"
                                        src={circle}
                                        alt=""
                                    />
                                </Tilty>
                            </div>
                        </div>
                    </div>
                    <div className="banner-dots-shape">
                        <Tilty>
                            <img
                                src={dots}
                                alt=""
                            />
                        </Tilty>
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default Successfully;