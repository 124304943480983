import React, { useState } from 'react';
import Slider from "react-slick";
import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import image1 from '../../assets/img/abacus/student_in_action_8.webp'
import image2 from '../../assets/img/abacus/student_in_action_5.webp'
import image3 from '../../assets/img/abacus/student_in_action_3.webp'
import ModalVideo from 'react-modal-video';

const Courses = () => {

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className='position-absolute top-0 start-50 translate-middle-x'>
                <button type='button'  onClick={onClick} className={className} aria-label="rightArrow" ><i className="fa-solid fa-circle-chevron-right orange-color "></i></button>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className='position-absolute top-0 start-50 translate-middle-x'>
            <button type='button' onClick={onClick} className={className} aria-label="left-Arrow" > <i className="fa-solid fa-circle-chevron-left orange-color  "></i></button>
            </div>

        );
    }

    const slilderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        // arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        className: "active",
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    // arrows: false,
                }
            }
        ]
    };

    const [isOpen, setIsOpen] = useState(false);
    const [viedoid, setviedoid] = useState('')
    const openModal = (id) => {
        setviedoid(id)
        setIsOpen(!isOpen);
    } 

    return (
        <React.Fragment>
            <div id="rs-team" className="rs-popular-courses style4 pt-80 pb-100 md-pt-70 md-pb-40 rs-team style1 nav-style2 back2-color"  >
                <ModalVideo channel='youtube' isOpen={isOpen} videoId={viedoid} onClose={() => { openModal(); }} />
                <div className="container"  >
                    <div className="row y-middle mb-10 ">
                        <div className=" text-center">
                            <div className="sec-title  text-center mb-50 md-mb-30">
                                <h2 className="title family mb-14 sm-mb-6">
                                WATCH LIGHTNING FAST MENTAL MATH CALCULATIONS AT <span className='title-change'>MASTERMIND ABACUS ACADEMY</span>
                                </h2>
                                <div className="desc ">
                                At Mastermind <span className='font-bold'>Abacus Academy</span>, our students are trained to perform mental math with unparalleled speed and accuracy, often outpacing calculators. Witness the impressive skills of our students as they demonstrate their mental math prowess.
                                </div>
                            </div>

                        </div>
                    </div>

                    <Slider {...slilderSettings} >
                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image1}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('mwL9vi4F9Tg'); }}>.</button>
                        </div>


                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image2}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('Y--QDTsdeyg'); }}>.</button>
                        </div>

                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image1}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('mwL9vi4F9Tg'); }}>.</button>
                        </div>

                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image3}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('nyAYbO7aCIk'); }}>.</button>
                        </div>

                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image2}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('Y--QDTsdeyg'); }}>.</button>
                        </div>

                        <div className="img-part media-icon">
                            <CourseSingleTwo
                                courseClass="courses-item"
                                courseImg={image3}
                            />
                            <button  className="popup-videos" onClick={() => { openModal('nyAYbO7aCIk'); }}>.</button>
                        </div>


                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Courses;