import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';
import AOS from 'aos';

// import wave from '../../assets/img/banner/banner-line.png';
// import circle from '../../assets/img/banner/banner-circle.png';
import dots from '../../assets/img/banner/banner-dots.png';
import teacherImg from '../../assets/img/abacus/Teacher/01_Mastermind.webp';
// import bgImg from '../../assets/img/abacus/Teacher/abacus-bg.jpg'
import { Modal } from 'react-bootstrap';
// import Franchise_inquiry_form from '../franchisee_inquiry_form/Franchise_inquiry_form';
import Teacher_inquiry_form from './Teacher_inquiry_form';

// const bgStyle = {
//     backgroundImage: `url(${bgImg})`,
//     'background-size': 'cover',
//     'background-position': 'center',
//     'height': '65vh',
//     'display': 'grid',
//     'align-items': 'center',
// }



const TeacherBanner = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner teacherBanner" >
                <div className="container relative">
                    <div className="col relative">
                        <div className="row-lg-6">
                           
                            <div className="banner-content pb-14">
                                {/* <div className="desc black-color mb-25" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="600">Skip long distance travels for <br/>
                                learning Abacus</div> */}
                                <div className="banner-title family white-color" data-aos="fade-right" data-aos-duration="1000">Explore the Future of <br/> Abacus Teacher Training:<br/>
                                 Empower Yourself with Mastermind
                                   </div>
                                <div className="banner-btn z-index-1 mt-20">
                                    <Link className="readon2 banner-style" onClick={() => setShow(true)}>APPLY NOW</Link>
                                </div>
                            </div>
                            {/* <div className="banner-circle-shape">
                                <Tilty>
                                    <img
                                        className="rotateme"
                                        src={circle}
                                        alt=""
                                    />
                                </Tilty>
                            </div> */}
                        </div>
                    </div>

                    <div className='col-lg-6 teacher-image'>
                        <img 
                        src={teacherImg}
                        alt='Mastermind Bacus'
                        />
                    </div>
                    <div className="banner-dots-shape">
                        <Tilty>
                            <img
                                src={dots}
                                alt="Mastermind Abcaus"
                            />
                        </Tilty>
                    </div>
                </div>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                      <Teacher_inquiry_form/>
                </Modal>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default TeacherBanner;