import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
// import RefundConent from './RefundContent';
// import DeliverContent from './DeliveryContent';
// import TermsContent from './PrivacyContent';
import PrivacyContent from './PrivacyContent';


const PrivacyMain = () => {

	return (
		<React.Fragment>

            <PrivacyContent/>
			
			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default PrivacyMain;