import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
// import ModalVideo from 'react-modal-video';

const FranchiseVideo = () => {
    // const [isOpen, setIsOpen] = useState(false);
    // const openModal = () => setIsOpen(!isOpen);
    return (
        <div className="rs-cta main-home">
            {/* <ModalVideo channel='youtube' isOpen={isOpen} videoId='Pcccj1L_oME' onClose={() => { openModal(); }} /> */}
            <div className="partition-bg-wrap">
                <div className="container">

                    <div className="row">
                        <div className="offset-lg-6 col-lg-6 pl-70 md-pl-14 relative">
                            <SectionTitle
                                sectionClass="sec-title3 mb-40"
                                titleClass="title  white-color mb-16"
                                title="JOIN THE LEADING ABACUS FRANCHISE PROGRAM."
                                descClass="desc white-color pr-100 md-pr-0"
                                description={<span style={{ fontSize: "25px" }}> Watch How Mastermind Abacus
                                    Franchise Support Your
                                    Business Growth.</span>}
                            />
                            {/* <div style={{
                                position: "absolute",
                                left: "-400px",
                                top: "100px",
                                fontSize: "50px",
                                color: "white",
                                cursor: 'pointer'

                            }} */}
                            {/* // onClick={openModal} */}
                            {/* > */}
                                {/* <i class="fa-regular fa-circle-play"></i> */}
                            {/* </div> */}
                            {/* <div className="btn-part">
                                <Link className="readon orange-btn transparent" to="/register">Register Now</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FranchiseVideo;