import React from 'react';
import Team from './TeamSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerAbout from './BannerAbout';
import AboutSection from './AboutSection';
import AbacusDifferent from './AbacusDifferent';
import WhatWeDo from './WhatWeDo';
import WhatNext from './WhatNext';
import Presence from './Presence';


const AboutMain = () => {

    return (
        <React.Fragment>

            <BannerAbout/>
            {/* AboutCounter Start */}
            {/* <AboutCounter /> */}
            {/* AboutCounter End */}
             <AboutSection/>
             <AbacusDifferent/>
             <WhatWeDo/>
            {/* AboutVideo Start */}
            {/* <AboutVideo /> */}
            {/* AboutVideo End */}

            {/* Team Section Start */}
            <Team />
            <WhatNext/>
             <Presence/>
            {/* Team Section End */}

            {/* Testimonial Section Start */}
            {/* <Testimonial /> */}
            {/* Testimonial Section End */}

            {/* Blog Section Start */}
            {/* <Blog /> */}
            {/* Blog Section End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default AboutMain;