import React from 'react';
// import { Link } from 'react-router-dom';





const InvestmentBanner = () => {
    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner bannerInvetment" >
                <div className="container">
                    <div className="banner-content text-center pt-100 md-pt-0" >
                        <h1 className="banner-title white-color">UNDERSTANDING YOUR POTENTIAL EXPENSES</h1>
                        {/* <h1 className="banner-title white-color">AND TRANSFORMATION FROM A MERE CALCULATING INSTRUMENT TO</h1>
                        <h1 className="banner-title white-color">A JOURNEY OF 3000 YEARS OF DEVELOPMENT</h1> */}
                        {/* <div className="desc black-color mb-50">Learn At your Own Pace Develope Your Career</div> */}
                        {/* <div className="banner-btn">
                            <Link className="readon2 orange banner-style" to="/about">Get Started Now</Link>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <!-- banner section end --> */}
        </React.Fragment>
    );
}

export default InvestmentBanner;