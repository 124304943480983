import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';
// import HomeSixMain from './HomeSixMain';

// import favIcon from '../../assets/img/fav.png';
// import Logo from '../../assets/img/logo/logo-dark.png';
// import stickyLogo from '../../assets/img/logo/logo.png';
import FranchiseFaqMain from './FranchiseFaqMain';

const FranchiseFaq = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Franchisee - FAQs &amp; Queries | Mastermind Abacus</title> 


		<meta name="description" content="In Franchisee FAQs section, find the answer of all possible Franchisee ownership queries for Mastermind Abacus Franchisee."/>
		<meta name="keywords" content="Franchisee, Franchisee FAQs, Mastermind Abacus Franchisee, Mastermind Abacus Franchisee FAQs, Mastermind Abacus Franchisee Fee, Franchisee Ownership Queries, Abacus Franchisee FAQs"/>
				
		
		<meta property="og:title" content="Franchisee - FAQs &amp; Queries | Mastermind Abacus"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/abacus-franchise/faq"/>
		<meta property="og:description" content="In Franchisee FAQs section, find the answer of all possible Franchisee ownership queries for Mastermind Abacus Franchisee."/>
	
		<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
		
		<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Franchisee - FAQs &amp; Queries | Mastermind Abacus"/>
		<meta name="twitter:description" content="In Franchisee FAQs section, find the answer of all possible Franchisee ownership queries for Mastermind Abacus Franchisee."/>
		<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFive
            parentMenu='home'
            TopBar='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={stickyLogo}
            // CanvasLogo={Logo}
            // CanvasClass='right_menu_togle hidden-md'
            headerClass='full-width-header header-style2'
            TopBarClass='topbar-area dark-parimary-bg'
            emailAddress='support@website.com'
            phoneNumber='(+088) 589-8745'
            />
            <FranchiseFaqMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />

        </React.Fragment>
    );
}


export default FranchiseFaq;