import { useState, useEffect } from 'react';
import logo from '../../assets/images/Mastermind Image 29.png';
// import { PiPhoneCallFill } from "react-icons/pi";
// import { FaPlus } from "react-icons/fa";

const Navbar = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > window.innerHeight) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className="bg-white fixed w-full z-50 top-0 start-0 shadow-md dark:border-gray-600 z-50" >
                <div className='w-full bg-orange-600 text-white pt-1 '><marquee>70% seats are booked. Book a demo now and reserve your seat!</marquee></div>
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3 pr-0">
                    <div className=' w-full flex justify-between'>
                        <a href="/" className="flex items-center space-x-3">
                            <img src={logo} className="h-12" alt="Mastermind Logo" />
                        </a>

                        <div className='flex'>
                            {showButton ? (
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }} type="button" className="btns btn-anim text-white md:flex hidden flex-row bg-green-500 items-center hover:bg-green-800 font-medium rounded-lg md:text-sm text-xs duration-700 md:px-4 text-center">
                                    {/* <FaPlus className='mr-3' /> */}
                                    BOOK A FREE DEMO NOW
                                </button>
                            ) : (<></>)}
                        </div>
                    </div>
                </div>
            </nav >
        </>

    );
}

export default Navbar;
