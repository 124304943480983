import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import SectionTitle from '../../components/Common/SectionTitle';

// import bgImage from '../../assets/img/cta/cta-bg.jpg';
import { HomeModal } from "../../components/Common/Modal/HomeModal";

const MapBottom = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const { ctaClass, ctaTitle, ctaDescription, btnText, btnLink } = props;

    useEffect(() => {
        AOS.init();
    })

    return (
        <div className={ctaClass ? ctaClass : 'rs-cta'} >
            {/* <div className="cta-img">
                <img
                    src={bgImage}
                    alt="Bg Image"
                />
            </div> */}
            <div className="cta-content text-center">
                {/* Section Title Start */}
                <SectionTitle
                    sectionClass="sec-title mb-40 md-mb-20"
                    titleClass="title family mb-16 md-mb-10"
                    title={<>GIVE A KICK START TO YOUR <br /><span className="title-change" > 
                        BETTER TOMORROW</span>  </>}
                    // descClass="desc"
                    // description={ctaDescription ? ctaDescription : `Watch How Mastermind Abacus Franchise Support Your Business Growth.`}
                    // animateName="fade-up"
                    // animateDuration="500"
                />
                {/* Section Title End */}
                <div className="btn-part" >
                    <div className="readon2" onClick={openModal}>Apply Now</div>
                </div>
            </div>
            <HomeModal isOpen={isModalOpen} onClose={closeModal} status={'TeacherIn'} />
        </div>
    );
}

export default MapBottom;