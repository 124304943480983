import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'rs-mega-menu menu-item-has-children current-menu-item plus' : 'rs-mega-menu menu-item-has-children plus'}><Link to="/">HOME </Link>
              
            </li>
            <li className={parentMenu === 'abacus-class' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">ABACUS CLASSES <i class="fa-solid fa-angle-down" style={{color:"black"}}></i> </Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/abacus-online-classes" className={location.pathname === "/abacus-online-classes" ? "active-menu" : ""}>Abacus Online Classes</Link>
                    </li>
                    <li>
                        <Link to="/abacus-classes-near-me" className={location.pathname === "/new_react/abacus-classes-near-me" ? "active-menu" : ""}>Abacus Classes Near Me</Link>
                    </li>
                    <li>
                        <Link to="/lingo-bingo" className={location.pathname === "/lingo-bingo" ? "active-menu" : ""}>Lingo Bingo</Link>
                    </li>
                    <li>
                        <Link to="/abacus-for-schools" className={location.pathname === "/about-2" ? "active-menu" : ""}>Abacus Classes In Shcool</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'resources' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#" as="#">RESOURCES <i class="fa-solid fa-angle-down" style={{color:"black"}}></i> </Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/about-abacus/what-is-abacus" className={location.pathname === "/course" ? "active-menu" : ""}>What is Abacus </Link>
                    </li>
                    <li>
                        <Link to="/about-abacus/history-of-abacus" className={location.pathname === "/course-2" ? "active-menu" : ""}>History Of Abacus</Link>
                    </li>
                    <li>
                        <Link to="/about-abacus/research-on-abacus" className={location.pathname === "/course-3" ? "active-menu" : ""}>Reasearch On abacus</Link>
                    </li>
                    <li>
                        <Link to="/abacus-math" className={location.pathname === "/course-4" ? "active-menu" : ""}>Abacus Math</Link>
                    </li>
                    <li>
                        <Link to="/abacus-worksheet-generator" className={location.pathname === "/course-5" ? "active-menu" : ""}>Abacus WorkSheet Generator</Link>
                    </li>
                   
                </ul>
            </li>
            <li>
                <Link to="/abacus-franchise">GET OUR FRANCHISE</Link>
                
            </li>

            <li className={parentMenu === 'teacher' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/become-a-teacher">BECOME A TEACHER</Link>
               
            </li>

            <li className={parentMenu === 'blog' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/blog">Blog</Link>
              
            </li>

            <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">ABOUT US <i class="fa-solid fa-angle-down" style={{color:"black"}}></i> </Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/about-us" className={location.pathname === "/blog" ? "active-menu" : ""}>About Company</Link>
                    </li>
                    <li>
                        <Link to="/gallery" className={location.pathname === "/blog" ? "active-menu" : ""}>Gallery</Link>
                    </li>
                    
                </ul>
            </li>
            <li className={parentMenu === 'contact' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/contact">
                    Contact
                </Link>
                
            </li>
        
        </React.Fragment>
    );
}

export default MenuItems;