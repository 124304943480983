import React from "react";
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
// import SectionTitle from '../../components/Common/SectionTitle';
// import bgImage from '../../assets/img/cta/cta-bg.jpg';

const About = (props) => {

    const { ctaClass } = props;

    useEffect(() => {
        AOS.init();
    })

    return (
        <div className={ctaClass ? ctaClass : 'rs-cta'} >
            <div className="cta-content text-center">
                {/* Section Title Start */}
                {/* <SectionTitle
                    sectionClass="sec-title md-mb-10"
                    titleClass="title mb-16 md-mb-10"
                    title={<>GET MOST <span style={{color:"#fd6c24"}}> ADVANCED ABACUS TEACHER </span> TRAINING</>}
                    descClass="desc"
                    description={<>If you are looking for Abacus teacher training, you are in the right place. Mastermind will give you the most advanced & the finest training through its live online portal; a company trained certified Master Trainer will be live with you during the training.</>}
                    // animateName="fade-up"
                    // animateDuration="500"
                /> */}


                <div className="sec-title mb-46 mt-36 md-mb-30">
                    <h1 className="title family">
                        GET MOST <span className="title-change"> ADVANCED ABACUS TEACHER </span> TRAINING
                    </h1>
                    <div className="desc ">
                        If you are looking for <span className="font-bold">Abacus teacher training</span>, you are in the right place. Mastermind will give you the most advanced & the finest training through its live online portal; a company trained certified Master Trainer will be live with you during the training.
                    </div>
                </div>
                {/* Section Title End */}
                {/* <div className="btn-part mt-30 md-mt-0" >
                    <Link className="readon2" to={btnLink ? btnLink : '/contact'}>Apply Now</Link>
                </div> */}
            </div>
        </div>
    );
}

export default About;