import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import RredLineSection from './RredLineSection';
import BannerFranchiseFaq from '../../components/Banner/BannerFranchiseFaq';
import FaqSection from './FaqSection';

const FranchiseFaqMain = () => {

	return (
		<React.Fragment>
			{/* Banner-area-start */}
			<BannerFranchiseFaq />
			{/* Banner-area-end */}


            <RredLineSection/>

            <FaqSection/>

			{/* scrolltop-start */}
			<ScrollToTop status={'FRANCHISE'} name={'FRANCHISE INQUIRY'}/>
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default FranchiseFaqMain;