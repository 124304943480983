import React from 'react';
import OnlineClassSection from './OnlineClassSection';
import BannerWhatAbacus from '../../components/Banner/BannerWhatAbacus';
import GateWayAbacus from './GateWayAbacus';
import MentalMath from './MentalMath';
import ScrollToTop from '../../components/Common/ScrollTop';

const WhatAbacusMain = () => {

	return (
		<React.Fragment>
			{/* Banner-area-start */}
			<BannerWhatAbacus />
			{/* Banner-area-end */}

			<GateWayAbacus/>
			
			<OnlineClassSection/>

		    <MentalMath/>

			<ScrollToTop status={'bookclass'} />
			
		</React.Fragment>
	);
}

export default WhatAbacusMain;