import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeModal } from '../../Common/Modal/HomeModal';

const TopHeader = (props) => {
	const { topBarClass, emailAddress, phoneNumber, Location } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(!isModalOpen);
	};

    const handleClick = () => {
        window.location.href = 'https://erp.mmtplonline.com/';
      };

    
    return (
        <div className={topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md rs-footer"}>
            <div className="container">
                <div className="row y-middle">
                    <div className="col-md-3" >
                        <ul className="topbar-contact">
                            {emailAddress ? 
                                <li>
                                    <i class="fa-solid fa-envelope"></i>
                                    <a href={'mailto:' + "info@mmtpl.com"}>info@mmtpl.com</a>
                                    {/* <a href="">info@mmtpl.com</a> */}

                                </li> : ""
                            }
                            {/* {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    {Location}
                                </li> : ""
                            } */}
                        </ul>
                    </div>
                    <div className="col-md-6 " >
                        <ul className="topbar-contact row-gap-3 ">
                            {phoneNumber ? 
                                <li>
                                    {/* <i className="flaticon-call" style={{}}></i> */}
                                    <a href='#' className='franchise-btn' onClick={openModal} >FRANCHISE INQUIRY
                                    </a>
                                </li> : ""
                            }
                            {/* {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    {Location}
                                </li> : ""
                            } */}
                        </ul>
                    </div>
                   <div className='row col-md-3 icon-prt text-right float-end ml-4' >
                   <div className="col-md-7 text-right md-text-left text-right" >
                            <ul className="footer-social d-flex  justify-content-evenly title-change " >
                                <li>
                                    <a href="https://www.facebook.com/mastermindabacusindia" target="_blank"  rel="noreferrer" aria-label="Go on facebook" >
                                        <i className="fa fa-facebook-f secondary-color" ></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://x.com/mmabacusonline" target='_blank'  rel="noreferrer" aria-label="Go on twitter">
                                        <i className="fa fa-x-twitter secondary-color"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mmabacusonline/" target='_blank'  rel="noreferrer" aria-label="Go on instagram">
                                        <i className="fa fa-instagram secondary-color" ></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/user/mastermindabacus" target='_blank'  rel="noreferrer" aria-label="Go on youtube">
                                        <i className="fa fa-youtube secondary-color" ></i>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    <div className="col-md-5 text-right">
                        <ul className="topbar-right">
                            <li className="login-register title-change"  >
                                <i className="fa fa-sign-in" ></i>
                                <Link to="route" target="_blank" onClick={handleClick} >Login</Link>
                            </li>
                            {/* <li className="btn-part">
                                <Link to="/contact" className="apply-btn">Apply Now</Link>
                            </li> */}
                        </ul>
                    </div>
                   </div>
                </div>
            </div>
            <HomeModal isOpen={isModalOpen} onClose={openModal} status={'FRANCHISE'}/>
        </div>
    );
}

export default TopHeader;