import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import HomeSixMain from './HomeSixMain';

import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo.png';
// import RefundPolicyMain from './RefundPolicyMain';
import DeliveryMain from './DeliveryMain';

const Deliver = () => {
    return (
        <React.Fragment>
            <Helmet>
            <title>Shipping Delivery Policy - Mastermind Abacus</title> 
				 <meta name="robots" content="noindex,nofollow" />
		
				<meta name="keywords" content=""/>
		<meta name="description" content="Get a complete understanding of the Shipping and Delivery Policy of Mastermind Abacus. About the trackingprocess of despatched goods through couriers."/>
		
		
		
		<meta property="og:title" content="Shipping Delivery Policy - Mastermind Abacus"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/shipping-delivery-policy"/>
		<meta property="og:description" content="Get a complete understanding of the Shipping and Delivery Policy of Mastermind Abacus. About the trackingprocess of despatched goods through couriers."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Shipping Delivery Policy - Mastermind Abacus"/>
		<meta name="twitter:description" content="Get a complete understanding of the Shipping and Delivery Policy of Mastermind Abacus. About the trackingprocess of despatched goods through couriers."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='home'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            <DeliveryMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default Deliver;