import React from 'react';
import { Route, BrowserRouter as Router, Switch, } from 'react-router-dom';
import HomeFive from '../pages/home-5';
import HomeSix from '../pages/home-6';
import HomeTwelve from '../pages/home-12';
import Gallery from '../pages/gallery';
import Faq from '../pages/faq';
import Blog from '../pages/blog';
import SinglePostRightSidebar from '../pages/blog/single-post-right-sidebar';
import ContactThree from '../pages/contact-3';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import StudentLanding from '../pages/Student Landing page';
import History from '../pages/abacusHistory';
import Franchise from '../pages/Franchise';
import TeacherSection from '../pages/Teachers';
import Investment from '../pages/investment';
import AbacusShcool from '../pages/abacusShcool';
import franchisee_inquiry from '../pages/franchisee_inquiry_form';
import Submitted_Successfully from '../pages/franchisee_inquiry_form/Submitted_Successfully_Page';
import WhatAbacus from '../pages/whatAbacus';
import AbacusResearch from '../pages/AbacusResearch';
import AbacusMath from '../pages/abacusMath';
import AbacusWorkSheet from '../pages/abacusWorksheet';
import About from '../pages/aboutAbacus';
import Terms from '../pages/termspage';
import FranchiseFaq from '../pages/franchiseFaq';
import Deliver from '../pages/deliverypage';
import RefundPolicy from '../pages/refundPolicy';
import Privacy from '../pages/Privacy';
import NearestAbacusClass from '../pages/Nearest-abacus-class';
import Career from '../pages/career';
import SubmittedSuccessfully from '../components/Common/Modal/Submitted_Successfully_Page';
import Submitted from '../pages/Student Landing page/Submitted_Successfully_Page';

const App = () => {
    return (
        <div className='App'>
            <Router>
                <Switch>
                    <Route path="/" exact component={HomeSix} />
                    <Route path="/abacus-classes-near-me" component={HomeFive} />
                    <Route path="/lingo-bingo" component={HomeTwelve} />
                    <Route path="/abacus-online-classes" component={StudentLanding} />
                    <Route path="/history-of-abacus" component={History} />
                    <Route path="/abacus-franchise" exact component={Franchise} />
                    <Route path="/become-a-teacher" component={TeacherSection} />
                    <Route path="/abacus-franchise/investment" component={Investment} />
                    <Route path="/abacus-franchise/faq" component={FranchiseFaq} />
                    <Route path="/abacus-classes-in-:blogname" component={NearestAbacusClass}/>
                    <Route path="/career-inquiry" component={Career}/>
                    <Route path="/inquiry_submit_on_domain_offline" component={SubmittedSuccessfully}/>
                    <Route path="/inquiry_submit_on_domain" component={Submitted}/>
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog_detail/:blogname" component={SinglePostRightSidebar} />
                    <Route path="/contact" component={ContactThree} />
                    <Route path="/abacus-for-schools" component={AbacusShcool} />
                    <Route path="/franchisee_inquiry" component={franchisee_inquiry} />
                    <Route path="/inquiry_submit_on_franchise_offline" component={Submitted_Successfully} />
                    <Route path="/about-abacus/what-is-abacus" component={WhatAbacus} />
                    <Route path="/about-abacus/history-of-abacus" component={History} />
                    <Route path="/about-abacus/research-on-abacus" component={AbacusResearch} />
                    <Route path="/abacus-math" component={AbacusMath} />
                    <Route path="/abacus-worksheet-generator" component={AbacusWorkSheet} />
                    <Route path="/about-us" component={About} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/shipping-delivery-policy" component={Deliver} />
                    <Route path="/cancellation-refund-policy" component={RefundPolicy} />
                    <Route path="/privacy-policy" component={Privacy} />
                    <Route path="/terms" component={Faq} />
                    
                    <Route component={Error} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
