import React from 'react';
import BannerStyleFour from '../../components/Banner/BannerStyleFour';
import About from './AboutSection';
// import Courses from './CoursesSection';
import AboutWork from './AboutWorkSection';
// import Counter from './CounterSection';
// import Team from './TeamSection';
// import Cta from './CtaSection';
// import Testimonial from './TestimonialSection';
// import Blog from './BlogSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BecomePart from './BecomePart';
import FranchiseBenefits from './FranchiseBenefits';
import MapSection from '../home-6/MapSection';
import MapBottom from './MapBottom';
import FranchiseVideo from './FranchiseVideo';
import BecomeFranchise from './BecomeFranchise';
import AbacusHistroy from './AbacusHistry';
// import MapSection from './FranchiseMapSection';
// import bgimg from '../../assets/img/abacus/Franchise/op_er.jpg'

const FrnachiseMain = () => {
	return (
		<React.Fragment>

			{/* BannerStyleFour-Start */}
			<BannerStyleFour />
			{/* BannerStyleFour-End */}

			{/* About-Start */}
			<About />
			{/* About-End */}

			<FranchiseVideo />

			{/* About-Work-Start */}
			<AboutWork />
			{/* About-Work-End */}

            <AbacusHistroy/>
			

			<BecomePart />

			<FranchiseBenefits />

			<BecomeFranchise/>


			<MapSection />


			<MapBottom />
			

			{/* scrolltop-start */}
			<ScrollToTop status={'FRANCHISE'} name={'FRANCHISE INQUIRY'}/>
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default FrnachiseMain;