import React from "react";
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
// import Tilty from 'react-tilty';
import SectionTitle from '../../components/Common/SectionTitle';


function TermsContent() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <div id="rs-about" className="rs-about style1 pt-50 pb-50 md-pt-70 md-pb-70 back-color" >
            <div className="container">
              

                <SectionTitle
                    sectionClass="sec-title mb-26"
                    subtitleClass="sub-title primary"
                    // subtitle="About Us"
                    titleClass="title"
                    title="TERMS & CONDITIONS"
                    descClass="desc pr-10"
                    description={<> The following are terms and conditions of accessing and/or using our website <a className="primary-color"  href="https://www.mastermindabacus.com">(URL: https://www.mastermindabacus.com)</a> , integrated online abacus learning platform or services. By using this website, platform or services (hereinafter referred to as “Services”), you are stating that you agree to be bound by all terms and conditions and privacy policy without modification, conditions or notices. <br/><br/>

                        Mastermind Tutorials Private Limited (“Company“, “We” or “Us” and their connotations) operates a website (URL: https://www.mastermindabacus.com) (together called as “Platform”) which is engaged in the services of Abacus mental arithmetic training for Students and Trainers <br/><br/>
                        
                        On acceptance of the below terms and conditions, the company grants a Revocable, Non- exclusive, Non- transferable, Limited license to download, Install, or access the website & Integrated platform (hereinafter will be referred as “SERVICES” and shall be read with the privacy policy which can be accessed at https://mastermindabacus.com/privacy-policy 
                            <br/><br/>
                        You will need parent consent to use the platform, if you are less than 18 years of age, as we implicit you as Child. Any parent or Trainer, or End User above the age of 18 years, will be hereinafter referred to as “YOU”. The term “Users” for the purposes of these Terms and conditions shall be read as You and / or Child.
                        <br/><br/>
                        The sole rights, to change, modify, add, or delete portions of these Terms at any time by posting the amended Terms lies with the company. The company can post amended terms at its judgment. Kindly check, these terms from time to time for new amendments. Your constant use of the Platform or Services after the posting of changes constitutes your binding to acceptance of new amendments. Apart from the above you will be assumed to accept supplementary terms and conditions, posted procedure or rules, in specific services or promotional offers at the discretion of the company. All such procedures, rules, product requirements or sometimes additional terms are hereby integrated by reference into the Terms.
                        <br/><br/>
                        Definitions and key terms for these Terms & Conditions:
                        <br/><br/>
                        Cookie: small amount of data generated by a website and saved by your web Browser, It is used to identify your browser, provide analytics, and remember   Information, about you such as your language preference or login information.
                        <br/><br/>
                        Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Master Mind Tutorials Private Limited, 51 Sampat Hills, Bicholi Mardana, Bypass, Indore, Pin : 452016  that is responsible for your information under this Privacy Policy. 
                        <br/><br/>
                        Country: where Mastermind Abacus or the owners/founders of Mastermind Abacus are based, in this case it is India.
                        <br/><br/>
                        Customer: refers to the company, organization or person that signs up to use the Mastermind Abacus Services to manage the relationships with consumers, or service users.
                        <br/><br/>
                        Device: any internet connected gadget such as a phone, tablet, computer or any other device that can be used to visit Mastermind Abacus website and use the services.
                        <br/>
                        IP address: Every device connected to the Internet is assigned a number known   as an Internet protocol (IP) address. These numbers are usually assigned in Geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.<br/><br/>
                        
                        Personnel: refers to those individuals who are employed by Mastermind Abacus or are under contract to perform a service on behalf of one of the parties.<br/><br/>
                        
                        Personal Data: any information that directly, indirectly, or in connection  with other information — including a personal identification number — allows   for the identification or identifiability of a natural person.
                        <br/><br/>
                        Service: refers to the service provided by Mastermind Abacus as described in the relative terms on this platform. 
                        <br/><br/>
                        Third-party service: refers to advertisers, contest sponsors, promotional & marketing partners, and others who provide our content or whose products or services we think may interest you.
                        <br/><br/>
                        Website / Platform: Mastermind Abacus’s site, and portal which can be accessed via this URL:   https://www.mastermindabacus.com/.
                        <br/><br/>
                        You: a person or entity that is registered with Mastermind Abacus to use the services.
                        <br/><br/>
                        1. Terms of Service:
                        <br/><br/>
                        You hereby accept the terms and conditions defined herewith, once you make your decision to use this Website/Platform/ or avail any services provided by the company. Kindly read the information cautiously. As Guardian or Parent, you provide your sanction for your child's use of Platform and services. As Trainer /End user you are also bound by these terms, with regards to the usage of the platform or services. You are lawfully bound by the set terms, and denote your acceptance, once you get the access of the platform or services. You can choose to exit this page immediately, and stop accessing the services, if you do not agree to the set terms of the agreement. If you violate any of these Terms & Conditions, we reserve the right to cancel your account or block access to your account without notice.
                        <br/><br/>
                        2. Description of Services
                        <br/><br/>
                        The Company facilitates online Abacus and Mental Math’s education services to children and to the adults who want to become an Abacus trainer. We provide lessons on various levels of Mastermind Abacus education, and metal math’s skills, to the Children between 5 years to 12 years or any person aged above 18 years, interested in becoming a trainer. (The training procedure, duration, and certification for the trainers will vary from the children) The basic concept is to create a live online abacus learning platform, which helps to learn abacus without any limitations of Geographical boundaries.
                        <br/><br/>
                        3. Username and Password:
                        <br/><br/>
                        You will be able to access the services of the platform only after registration, for which you will have to disclose following details 
                        a. Name,  Guardian’s name
                        b. Phone number, Email id, contact  Address, Photograph, Location
                        c. Date of Birth
                        d. Gender and other demographics.
                        <br/><br/>
                        In case you are below 18 years of age, you are not allowed to register for this program unless such registration is done with a consent of a parent or legal guardian. 
                        <br/><br/>
                        After registration the company will provide you unique log in credentials in the form of a Username and Password
                        <br/><br/>
                        You understand that the username and Password are confidential credentials only meant for your use exclusively. You are not permitted to sharing or using these credentials with anyone, this may lead to blocking the access and services, followed by termination of this agreement without notice. 
                        <br/><br/>
                        You are exclusively accountable for maintaining confidentiality of the log in credentials of the registered account, and all conduct associated with it. You agree not to, and you will not permit others to:  License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the service or make the platform available to any third party.
                        <br/><br/>
                        You take the sole responsibility to inform the Company with utmost urgency, once you doubt the use of your log in credentials, for the registered account by someone else. You also give your consent to take corrective steps to stop such illegal activity, and help the company in any investigation related to this illegal activity. Under any circumstances, the company cannot be held accountable for any claims arising out of misuse of the log in credentials by a third person or party or due to our failure to maintain the confidentiality and security of your login credentials for the registered account.
                        <br/><br/>
                        4. Demo Class:
                        <br/><br/>
                        The applicable terms and conditions will also apply for the Free Demo class. The Company offers only one demo class of 40 to 60 minutes to the children between 5 years to 14 years (accompanied with parents)  “FREE OF COST “at a specified Day / Date & Time, with prior appointment. The sole objective of this Demo class is to give an opportunity to the Child & Parents to have a feel of services provided, before enrollment. Attempt to multiple enrollments for Demo class, with illegal means, and without the written permission of the company shall be treated as violation of the agreed terms. 
                        <br/><br/>
                        5. Mastermind Abacus- Course structure:
                        <br/><br/>
                        The company has designed the program in 8 Levels, with schedules decided for every class, for the Children and the Trainers, who are expected to complete the Levels as per the pre-defined guidelines. The Company has network of trainers to train the children, and Master Trainers to train the Trainers.
                        <br/><br/>
                        As Student or Trainer you will have the access to the platform, which will contain Digital  Content, Educational Materials and tool, Specific Curriculum and schedules, documents and other information and data (“Schedules”) which may be in Audio, Video, math game, Written, Graphic, Recorded, photographic, or any other format in the entire program. 
                        <br/><br/>
                        The Company can anytime Modify, Upgrade, Replace, Update, Rectify or Improvise, with sole discretion,
                        <br/><br/>
                        6. License to Use:
                        <br/><br/>
                        The company grants a Revocable, Non- exclusive, Non- transferable, Limited license to download, Install, view or access the website & Integrated platform only for the purpose of providing Abacus learning or Abacus teaching. The Company reserves the right to reject or suspend, at its sole discretion, your access to any or all portion of the Platform. The license given to your child is only meant for personal and non -commercial use and license meant for the trainer is for the purpose of training students who are registered on this platform and allocated by the company in your panel.  Any other rights which has not been specifically granted to you, is held in reserve with the company. 
                        <br/><br/>
                        Without the written approval of the company, You do not have the authority or consent to reproduce, transmit, distribute, sub-license, broadcast, disseminate, or prepare derivative works of the Course content, or any part thereof. In anyway, or thru any mode of communication or means, for any purpose except, as motioned above.
                        <br/><br/>
                        7. Intellectual Property Rights:
                        <br/><br/>
                        You undertake to confirm and accept that the exclusive, sole ownership of the platform lies with the company. The Content, The Syllabus, The Schedules and The Services are developed by the company, hence the company inherits all the Intellectual Property Rights and other proprietary rights in the Platform. 
                        <br/><br/><br/><br/>
                         
                        
                        The Company at regular intervals may upload Audios (Recordings), Videos (Recorded Lectures), Contents, and other materials on the website or the integrated platform, which shall be the absolute and exclusive property of the company. You unconditionally agree not to Transmit, Re Transmit, Reproduce, Publish, Distribute, Post, Share or make available the Audios (Recordings), Videos (Recorded Lectures), Contents, and other materials, or any part thereof available on the Website /Platform in any mode whatsoever.
                        
                        <br/><br/><br/><br/>
                        
                        Furthermore, the sole / Exclusive rights for Trademarks, copyrights, Designs, Logos, Trade secrets, Know how, any all Intellectual property rights, with regards to all details and information available on the platform lies exclusively with the company. This includes (Except text) Photos, Graphics, Illustrations, Apps, and logos, baring reading materials, content, and practice sheets, and Home assignments , which is offered to you from www.mastermindabacus.com. You are not authorized to copy, download, publish, distribute, or reproduce any information available on the platform either on social media, or otherwise, in any form without the prior written permission from the company.
                        <br/><br/>
                        Additionally, the company owns and retains exclusive rights on the Sound /audio recordings, video recordings, images, photos, pictures/ images clicked during the classes, lectures delivered by the Company’s trainers, text and other learning materials  posted on this Platform.
                        <br/><br/>
                        Without the written permission of the company, you agree not to record, make videos or sound/ audio recordings, take screen shots, click pictures and shall not download, publish, transmit, display, reproduce, transmit, distribute, post, share or make copies of any of the classes/ lectures that are conducted by the Company (including the demo classes), video recordings, sound/audio recordings, images, photos, pictures/ images, text or other material, either in full or in part. You shall be held legally responsible, as per the law of the land, and it will be considered as direct violation in the agreed terms and conditions, “If you obtain Videos, any recordings, sound/audio recordings, screen shots, pictures, images, material or content, without the written approval of the Company. All other rights are reserved.
                        <br/><br/>
                        8. Use of the Platform by the Child:
                        <br/><br/>
                        You explicitly accept and undertake:
                        <br/><br/>
                        a. That, you have absolute legal rights and you are capable to enter into this agreement on behalf of the Child, or Directly as a Trainer.
                        <br/><br/>
                        b. That, you grant your permission and consent to the company for your Child, to take part, and attend classes, courses, test, sessions, Competitions or any other programs conducted on the platform, and with regards to the services provided by the company.
                        <br/><br/>
                        c. That, you will ensure active participation of the child in the class.
                        <br/><br/>
                        d. That, you also accept full and complete responsibility arising out of your / child’s direct or indirect overall behaviour and actions.
                        <br/><br/>
                        9. Payment and Refund Policy:
                        <br/><br/>
                        The use of the platform by the Child or you attracts payment in lieu of the services provided by the company. The payment will be made according the plan opted by you. The website of the company offers various payment methods, like Online Bank Transfer, Wallet Payment and Card payment and you can use the same as per your convenience. The company reserves the right to stop services in case the payment is not made on time. 
                        <br/><br/>
                        Please be informed that the payment gateway processes are managed by the terms and conditions of the third party service providers as mentioned on the platform, and the users hereby agree to those terms. Kindly read this in conjunction with Clause 10 of this agreement.
                        <br/><br/>
                        The legitimate Credit/ Debit/ Cash card and online bank transfer and any other payment gadgets, are processed thru specific payment gateway and payment issuing body, which is ruled by the terms agreed with the user and the respective bank / payment instrument issuing body. The company cannot be held liable for illegal use, Lost amounts, Deception, Payment refunds etc. in the transactions. The user is also liable to pay the bank charges to the company levied for such services. 
                        <br/><br/>
                        Fee once paid is non-refundable in any case. Any refunds related to the third party usage or services, will be governed by the terms and conditions agreed with the third party.
                        <br/><br/>
                        10. Third Party Services:
                        <br/><br/>
                        The company utilizes, the services of third party, which contain the specific software or products to provide services, the absolute ownership of these software and products lies with the third party, hence you are governed, by the terms and conditions between you and the third party. You agree and accept that the company cannot be held liable for any break down, in accuracy, or flaws in the services provided through these third party, 
                        <br/><br/>
                        Kindly read the User agreement and privacy policy of the third party before using their services.
                        <br/><br/>
                        11. Third Party Permission:
                        <br/><br/>
                        The company has utilized the services of various third party vendors and service providers. On agreeing these terms you agree and allow the third party vendors to use your information. The company will not be Liable/ Accountable, for any dispute arising between you and the third party. 
                        <br/><br/>
                        12. Recording and screenshots of the classes or conversation:
                        <br/><br/>
                        By using this platform, you agree and allow the company to record the classes/ conversation or take screenshots during the classes. The company reserve all the rights and ownership on such recordings and screenshots. The company also reserve all the rights and ownership on any idea or improvement suggested by you in the class or conversation on the platform. The company shall have sole and exclusive uninterrupted rights to use worldwide, copy, modify, adapt,  prepare derivative works from, distribute, perform, and display any/all such ‘recordings, screenshots, idea or suggestion with no claims and will be royalty free.
                        <br/><br/>
                        13. User Communication:
                        <br/><br/>
                        You hereby unequivocally permit “MASTERMIND TUTORIALS PRIVATE LIMITED”to send emails, telephonic calls, Whatsapp messages and Text messages, with an objective to provide alerts, and information with regards to the services offered.
                        <br/><br/>
                        14. Rules of Conduct:
                        <br/><br/>
                        You should comply with the applicable laws of your territory, and if the laws do not permit you to access the website or the Platform, you must fulfill those legal restrictions or stop accessing the services immediately. You assure that the information provided by you for using the Services of the company /and accessing the platform is correct and complete in all terms. 
                        <br/><br/>
                        Despite any other conditions to these terms, you additionally accept and agree NOT TO:
                        <br/><br/>
                        Decompile, Modify, Adapt, Merge, Hack, Attempt to Hack, Translate, Reverse engineering, Disassemble, Create derivative  works out of service or any part of the platform (save to the scope which mainly cannot be forbidden in harmony with the relevant mandatory law in your jurisdiction);
                        <br/><br/>
                        a. Utilize the Services in any other way. except as per the above agreed terms only.
                        <br/><br/>
                        b. Share the services to anyone thru any communication, or adding any one thru your link.
                        <br/><br/>
                        c. Use the information / Contents sent to you, for unwanted bulk emails.
                        <br/><br/>
                        It is pertinent for you to access the platform only through the legalized servers, You should not attempt in any-way to bypass the authorized servers / or use third party software to tamper any part of the platform, either for personal advantage over other users, You specifically allow the company to examine your computer’s information for the purpose of spotting said official third party programs.
                        <br/><br/>
                        15. Termination:
                        <br/><br/>
                        The company retains the sole rights to Limit, Eliminate, Withdraw or Defer, access to the use of the platform, or any part of the services, without any prior notice or information. The judgment of the company for deciding the Deviation / Violation in the agreed terms will be at its sole discretion. You can also terminate this agreement, by stopping to access to the platform, or services, destroying all hard and soft copies of services received, or a part of service under your control. The termination will no way affect any rights or remedies, which have accumulated till the period of termination.
                        <br/><br/>
                        16. Validity:
                        <br/><br/>
                        If any terms, part or provision of this agreement is found invalid, illegal, or unenforceable, by any court or competent authority, that portion of the term /provision can be considered for deleting, to the extent necessary, but the validity and enforceability of the other terms and provisions will remain unchanged
                        <br/><br/>
                        17. Assignment:
                        <br/><br/>
                        The company reserves the sole rights to transfer the licenses granted to you, you will not have any authority to transfer the licenses granted. Any attempt by you in this regard, will be considered as null and void. 
                        <br/><br/>
                        18. Waiver:
                        <br/><br/>
                        No waiver of any terms of this agreement shall be treated as a permanent waiver of the respective term or any other term and the Company’s failure to assert any provision or right with reference to this agreement shall not constitute a waiver of such provision and rights. 
                        <br/><br/>
                        19. Limited Liability:
                        <br/><br/>
                        For any violation of the agreed terms by you or your child, you will be held personally liable to compensate the company, for all damages and losses, arising from the guilty, non execution of the responsibility of these agreed terms. You undertake to discharge the company from all financial or legal claims, filed by anyone with regards to the violation carried by you or your Child. You further undertake to pay the cost of Legal proceedings, including all court and legal fees. This is applicable only if you are responsible for the breach.
                        <br/><br/>
                        The Company does not warrant that the Service will be uninterrupted or error-free. The Company will not be liable for the failure of the services if such failure is caused by the occurrence of any unforeseen contingency beyond the reasonable control of Company including Internet outages, communications outages, fire, flood, war or act of God.
                        <br/><br/>
                        Furthermore the company also clarifying the following points: 
                        <br/><br/>
                        a. The company has built the platform with all contents to the best of its ability to provide quality training to the students by the trainer, but in no way the company gives 100 percent assurance /Guarantee or Warranty on the Competence, Correctness, Validity, Totality or Appropriateness for any function.
                        <br/><br/>
                        b. The company certifies the trainer, based on the individual performance during the course of trainer’s training, and also updates the Curriculum, Schedules and the Syllabus, but does not take any responsibility or guarantee for the quality of Training delivered by the trainer on the platform. If you or your child notices any Immoral, Unethical, Illegitimate act by the trainer, which may include anyone of the following: 1. Abusive language, 2. Sexual Language 3. Portray sexual acts. or pornographic pictures or descriptions. Etc. Please notify the company promptly on the contact details provided in Clause 22 of this agreement The Company will take steps, as per the law of the land. BUT please note that the company without doubt disowns any legal responsibility or accountability for any actions as mentioned above.
                        <br/><br/>
                        c. You cannot hold the company responsible for Immoral, Illegal, or Unethical actions of your Child, At the Behest /Recommendation from the trainer. You undertake/ Accept sole responsibility to keep a close watch, on the activities of your Child. 
                        <br/><br/>
                        d. The Company shall not be liable for any direct, indirect damages for loss of profits, revenue, data, arising from your access to, or use of, the Platform or any content provided on or through the Platform.
                        <br/><br/>
                        e. The Company is legally responsible only to provide the services of the platform with rational talent and care. The company only assists the Child to learn the Abacus program “AS IN “basis.
                        <br/><br/>
                        f. The Company's legal responsibility for any and all claims in the combined, arising out of or related to your use of the Platform, shall not under any circumstances surpass the amounts actually paid by you to the Company for its Services.
                        <br/><br/>
                        You own the sole responsibility for using the services of the external sites on this platform; the company has not verified or reviewed these external sites, (which the company uses the third party to facilitate services). The company takes NO GAURANTEE in relation to the services provided by these external sites, 
                        <br/><br/>
                        20. Government Laws and Jurisdiction:
                        <br/><br/>
                        Any difference, disagreement, or dispute  either in understanding or otherwise, of the Terms and other Policies on the Platform, between the parties hereto, shall be referred to an independent arbitrator who will be appointed by the company on shared cost and his decision shall be final and binding on the parties hereto. The above arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time. The seat of arbitration shall be held in Indore MP (India) and the language shall be English.
                        <br/><br/>
                        The Terms shall be construed in accordance with the applicable laws of India. For proceedings arising therein the Courts in Indore (India) shall have exclusive jurisdiction.
                        <br/><br/>
                        Without any prejudice to particulars listed in Clause 22, the Company shall have the right  to seek and obtain any injunctive, provisional or interim relief from any court of competent jurisdiction to protect its trade-mark or other intellectual property rights or confidential information or to preserve the status quo pending arbitration. 
                        <br/><br/><br/><br/>
                         
                        
                        21. Entire Agreement:
                        <br/><br/>
                        The entire agreement defining the rules of usage of the platform, constitutes, This Agreement, along with the Privacy Policy, Terms of Use, and any additional guidelines, rules, and/or disclaimers posted on the Platform ,and it surpasses any prior agreements, if any, relating to any affair dealt within this Agreement. 
                        <br/><br/>
                        22. Contact:
                        <br/><br/>
                        If you need any further clarifications or questions about these Terms, please contact us by email or postal mail on the following address:
                        <br/><br/>
                        Name: Mastermind Tutorials Private Limited
                        <br/><br/>
                        E-mail id: info@mmtpl.com 
                        <br/><br/>
                        Address: 51 Sampat Hills Bicholi Mardana By pass Indore – 452016, (MP) India.</>}
                    animateName="fade-up"
                    animateDuration="1200"
                />

            </div>
        </div>
    );
}

export default TermsContent;