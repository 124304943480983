import React from 'react';
import { Helmet } from 'react-helmet';
// import HeaderStyleSix from '../../components/Layout/Header/HeaderStyleSix';
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
import HomeTwelveMain from './HomeTwelveMain';
// import Footer from '../../components/Layout/Footer/Footer';

// import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/img/logo/logo-green.png';
// import mobileLogo from '../../assets/img/logo/logo-green.png';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';

const HomeTwelve = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Abacus program for 4 to 6 years old | Mastermind Lingo Bingo</title> 
				<meta name="keywords" content="Abacus Franchise, Abacus Franchisee, Abacus Franchisor Abacus, Abacus Books, Abacus Worksheets, Abacus Practice Sheet, Abacus Training, Abacus Online Training, Franchisee Opportunity"/>
		<meta name="description" content="Lingo Bingo Is An Exclusive Game-Based Abacus Math Program For Kids Of 4 To 6 Years, By Mastermind Abacus"/>
		
		
		
		<meta property="og:title" content="Abacus program for 4 to 6 years old | Mastermind Lingo Bingo"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/lingo-bingo"/>
		<meta property="og:description" content="Lingo Bingo Is An Exclusive Game-Based Abacus Math Program For Kids Of 4 To 6 Years, By Mastermind Abacus"/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Abacus program for 4 to 6 years old | Mastermind Lingo Bingo"/>
		<meta name="twitter:description" content="Lingo Bingo Is An Exclusive Game-Based Abacus Math Program For Kids Of 4 To 6 Years, By Mastermind Abacus"/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
              parentMenu='abacus-class'
              TopBar='enable'
              headerNormalLogo={Logo}
            //   headerStickyLogo={stickyLogo}
              CanvasLogo={Logo}
              CanvasClass='right_menu_togle hidden-md'
              headerClass='full-width-header header-style2'
              TopBarClass='topbar-area dark-parimary-bg'
              emailAddress='support@website.com'
              phoneNumber='(+088) 589-8745'
            />
            <HomeTwelveMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default HomeTwelve;

