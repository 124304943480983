import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
// import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
// import HomeFiveMain from './TeacherMain';

// import favIcon from '../../assets/img/fav.png';
// import Logo from '../../assets/img/logo/logo3.png';
// import stickyLogo from '../../assets/img/logo/logo.png';
// import darkLogo from '../../assets/img/logo/logo-dark.png';
// import smallDeviceLogo from '../../assets/img/logo/logo-dark2.png'
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import TeacherMain from './TeacherMain';

const TeacherSection = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Abacus Teacher Training | Mastermind</title> 
				<meta name="keywords" content="Abacus Franchise, Abacus Franchisee, Abacus Franchisor Abacus, Abacus Books, Abacus Worksheets, Abacus Practice Sheet, Abacus Training, Abacus Online Training, Franchisee Opportunity"/>
		<meta name="description" content="Get abacus teacher training at mastermind and access to the world's most advanced abacus learning portal."/>
		
		
		
		<meta property="og:title" content="Abacus Teacher Training | Mastermind"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/become-a-teacher"/>
		<meta property="og:description" content="Get abacus teacher training at mastermind and access to the world's most advanced abacus learning portal."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Abacus Teacher Training | Mastermind"/>
		<meta name="twitter:description" content="Get abacus teacher training at mastermind and access to the world's most advanced abacus learning portal."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            {/* <HeaderStyleThree
                parentMenu='home'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                responsiveLogo={smallDeviceLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify"
                TopBarClass="topbar-area dark-parimary-bg"
            /> */}
            <HeaderStyleFour
            parentMenu='teacher'
            TopBar='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={stickyLogo}
            // CanvasLogo={Logo}
            // CanvasClass='right_menu_togle hidden-md'
            headerClass='full-width-header header-style2'
            TopBarClass='topbar-area dark-parimary-bg'
            emailAddress='support@website.com'
            phoneNumber='(+088) 589-8745'
            />
            <TeacherMain />
            <FooterStyleTwo />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default TeacherSection;