import React from 'react';
import Commenting from '../../components/Widget/Commenting';
const BlogDetails = (props) => {
    const { BlogDetailData } = props

    return (
        <>
            {/* <PostContent /> */}
            <div className="blog-deatails">
                <div className="bs-img d-flex justify-center">
                    {BlogDetailData ? <img src={BlogDetailData.blog_detail.image_url} alt="Post Image" /> : null}
                </div>
                <div className='text-center'>
                </div>
                <div className="blog-full mt-0">
                {BlogDetailData ? <h1 className="title mb-10 mt-0" >{BlogDetailData.blog_detail.subject}</h1> : null}
                    <ul className="single-post-meta">
                        <li>
                            {BlogDetailData ? <span className="p-date"> <i className="fa fa-calendar-check-o"></i> {BlogDetailData.blog_detail.blog_date} </span> : null}
                        </li>
                        <li>
                            <span className="p-date"> <i className="fa fa-user-o"></i> Admin </span>
                        </li>
                    </ul>
                    {BlogDetailData ?
                        <div dangerouslySetInnerHTML={{ __html: BlogDetailData.blog_detail.shot_content }}>
                            
                        </div>
                        : null
                    }
                </div>
            </div>
            {/* <PostNavigation /> */}
            <Commenting />
        </>
    );
}

export default BlogDetails;