import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { HomeModal } from '../../Common/Modal/HomeModal';

const FranchiseTopBar = (props) => {
	const { topBarClass, emailAddress} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(!isModalOpen);
	};
    return (
        <div className={topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md rs-footer"}>
            <div className="container">
                <div className="row y-middle">
                    <div className="col-md-8" style={{marginRight:"50px"}}>
                        <ul className="topbar-contact row-gap-3">
                            {emailAddress ? 
                                <li>
                                    <i className="flaticon-email"></i>
                                    <a href={'mailto:' + "info@mmtpl.com"}>info@mmtpl.com</a>
                                    {/* <a href="">info@mmtpl.com</a> */}

                                </li> : ""
                            }
                            
                            {/* {Location ? 
                                <li>
                                    <i className="flaticon-location"></i>
                                    {Location}
                                </li> : ""
                            } */}
                        </ul>
                    </div>
                   <div className='row col-md-3'>
                   <div className="col-md-8 text-right md-text-left" >
                            <ul className="footer-social d-flex  justify-content-evenly " style={{color: "#FD6C24"}}>
                                <li>
                                    <a href="https://www.facebook.com/mastermindabacusindia" target="_blank" rel="noreferrer" >
                                        <i className="fa fa-facebook-f" style={{color: "gray"}}></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://x.com/mmabacusonline" target='_blank' rel="noreferrer" >
                                        <i className="fa fa-x-twitter" style={{color: "gray"}}></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mmabacusonline/" target='_blank' rel="noreferrer" >
                                        <i className="fa fa-instagram" style={{color: "gray"}}></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/user/mastermindabacus" target='_blank' rel="noreferrer" >
                                        <i className="fa fa-youtube" style={{color: "gray"}}></i>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    <div className="col-md-4 text-end">
                        <ul className="topbar-right">
                            <li className="login-register" style={{color:'#FD6C24'}} >
                                <i className="fa fa-sign-in" style={{color:'#FD6C24'}} ></i>
                                <a href="https://www.mmtplonline.com/franchise2" target='_blank' rel="noreferrer"  style={{color:'#FD6C24'}}>Login</a>
                            </li>
                            {/* <li className="btn-part">
                                <Link to="/contact" className="apply-btn">Apply Now</Link>
                            </li> */}
                        </ul>
                    </div>
                   </div>
                </div>
            </div>
            <HomeModal isOpen={isModalOpen} onClose={openModal} status={'FRANCHISE'}/>
        </div>
    );
}

export default FranchiseTopBar;