import React from 'react';
import { Helmet } from 'react-helmet';
import BlogMain from './BlogMain';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import Footer from '../../components/Layout/Footer/Footer';
// import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
// import SearchModal from '../../components/Layout/Header/SearchModal';
// Image
// import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';

const Blog = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Everything About Abacus | Mastermind Blogs</title> 
				<meta name="keywords" content=""/>
		<meta name="description" content="Stay connected!  To Mastermind Abacus Blogs for all the latest facts, learning’s, information about Abacus, Mental Math, child development and much more."/>
		
		
		
		<meta property="og:title" content="Everything About Abacus | Mastermind Blogs"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/blog"/>
		<meta property="og:description" content="Stay connected!  To Mastermind Abacus Blogs for all the latest facts, learning’s, information about Abacus, Mental Math, child development and much more."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Everything About Abacus | Mastermind Blogs"/>
		<meta name="twitter:description" content="Stay connected!  To Mastermind Abacus Blogs for all the latest facts, learning’s, information about Abacus, Mental Math, child development and much more."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='blog'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="OUR BLOGS - WE RESEARCH, DOCUMENT & SHARE KNOWLEDGE"
                pageName=""
                breadcrumbsImg={bannerbg}
                // <img src="/static/media/2.d3762604.jpg" alt="Breadcrumbs Image"></img>
            />
            {/* breadcrumb-area-End */}

            {/* Blog Main */}
            <BlogMain />
            {/* Blog Main End */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-style mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}

            {/* <FooterStyleTwo
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            /> */}
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    );
}


export default Blog;

