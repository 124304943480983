import React from 'react';
// import BannerStyleFour from '../../components/Banner/BannerStyleFour';
import About from './AboutSection';
// import Courses from './CoursesSection';
// import AboutWork from './AboutWorkSection';
// import Counter from './CounterSection';
// import Team from './TeamSection';
// import Cta from './CtaSection';
// import Testimonial from './TestimonialSection';
// import Blog from './BlogSection';
// import ScrollToTop from '../../components/Common/ScrollTop';
import TeacherBanner from './TeacherBanner';
import HowBecomeTeacher from './HowBecomeTeacher';
import TeacherBenefite from './TeacherBenefite';
import StudentAction from './StudentAction';
import MapSection from '../home-6/MapSection';
import MapBottom from './MapBottom';

const TeacherMain = () => {
	return (
		<React.Fragment>

			{/* BannerStyleFour-Start */}
			<TeacherBanner />
			{/* BannerStyleFour-End */}

			{/* About-Start */}
			<About />
			{/* About-End */}


			<HowBecomeTeacher />


			<TeacherBenefite />

			<StudentAction />

			<MapSection />
			<MapBottom />
		

			{/* scrolltop-start */}
			{/* <ScrollToTop /> */}
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default TeacherMain;