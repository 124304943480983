import React from 'react';
import About from './AboutSection';
// import ScrollToTop from '../../components/Common/ScrollTop';
import BannerMath from './BannerMath';
import OnlineClassSection from '../whatAbacus/OnlineClassSection';
// import OnlineClasses from './OnlineClasses';

const AbacusMathMain = () => {
	return (
		<React.Fragment>

			{/* BannerStyleFour-Start */}
			<BannerMath />
			{/* BannerStyleFour-End */}

			{/* About-Start */}
			<About />
			{/* About-End */}

             <OnlineClassSection/>

			{/* <MathBenefit /> */}

			
{/* <TeacherBenefite/> */}

{/* <StudentAction/>

<MapSection/>
<MapBottom/> */}
			{/* Courses-Start */}
			{/* <Courses /> */}
			{/* Courses-End */}

			{/* About-Work-Start */}
			{/* <AboutWork /> */}
			{/* About-Work-End */}

			{/* Counter-Start */}
			{/* <Counter /> */}
			{/* Counter-End */}

			{/* Team-Start */}
			{/* <Team /> */}
			{/* Team-End */}

			{/* Cta-Start */}
			{/* <Cta
				ctaClass="rs-cta"
				ctaTitle="Admission Open for 2020"
				ctaDescription="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eius to mod tempor incididunt ut labore et dolore magna aliqua. Ut enims ad minim veniam. Aenean massa. Cum sociis natoque penatibus et magnis."
				btnText="Apply Now"
			/> */}
			{/* Cta-End */}

			{/* Testimonial-Start */}
			{/* <Testimonial /> */}
			{/* Testimonial-End */}

			{/* Blog-Start */}
			{/* <Blog /> */}
			{/* Blog-End */}

			{/* scrolltop-start */}
			{/* <ScrollToTop /> */}
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default AbacusMathMain;