import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeModal } from '../../Common/Modal/HomeModal';

const FranchiseManu = (props) => {
    // const { parentMenu, secondParentMenu } = props;

    const location = useLocation();
    
	const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };


    return (
        <React.Fragment>
            <li className={location.pathname === '/abacus-franchise' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}><Link to="/abacus-franchise">HOME </Link>

            </li>
            <li className={location.pathname === '/abacus-franchise/investment' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/abacus-franchise/investment">Investment </Link>

            </li>
            <li className={location.pathname === '/abacus-franchise/faq' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="/abacus-franchise/faq" as="#">FAQS </Link>
            </li>
            {/* <li>
                <Link to="/become-a-teacher">ABACUS WORKSHEET GENERATOR</Link>

            </li> */}

            <span className="btn-part rounded" style={{color:"white", backgroundColor:"#FD6C24", paddingBlock:"10px", paddingInline:"20px"}}>
                <Link onClick={openModal}  className="apply-btn white-color">FRANCHISE INQUIRY</Link>
            </span>
            <HomeModal  isOpen={isModalOpen} onClose={openModal} status={'FRANCHISE'}  mess={"FRANCHISE INQUIRY"}  id={'3'}  />
        </React.Fragment>
    );
}

export default FranchiseManu;