import React from 'react';
import { Helmet } from 'react-helmet';
// import Header from '../../components/Layout/Header/Header';
// import Footer from '../../components/Layout/Footer/Footer';
// import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
// import SearchModal from '../../components/Layout/Header/SearchModal';

import GalleryPart from './GalleryPart';

// Image
// import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';

// import bannerbg from '../../assets/img/abacus/gallery/mastermind-abacus-gallery.jpg';
import bannerbg from '../../assets/img/abacus/gallery/mastermind-abacus-gallery-new.jpg';

// import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';

const Gallery = () => {

    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Mastermind Abacus - Image gallery, a pictorial representation</title>
                <meta name="keywords" content="Mastermind Abacus State level competitions, State Level Competitions, events, National Level Abacus Competition, International Level State Competition, Mastermind Abacus" />
                <meta name="description" content="Glimpses of Mastermind Abacus National & International level competitions." />



                <meta property="og:title" content="Mastermind Abacus - Image gallery, a pictorial representation" />
                <meta property="og:site_name" content="Mastermind Abacus" />
                <meta property="og:url" content="https://www.mastermindabacus.com/gallery" />
                <meta property="og:description" content="Glimpses of Mastermind Abacus National & International level competitions." />

                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@mastermindabac2" />
                <meta name="twitter:title" content="Mastermind Abacus - Image gallery, a pictorial representation" />
                <meta name="twitter:description" content="Glimpses of Mastermind Abacus National & International level competitions." />
                <meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png" />
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='about'
                TopBar='enable'
                headerNormalLogo={Logo}
                // headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="MASTERMIND ABACUS - IMAGE GALLERY, A PICTORIAL REPRESENTATION"
                pageName="Gallery"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            <GalleryPart />

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}

            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            {/* <SearchModal /> */}
        </React.Fragment>
    );
}

export default Gallery