import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
// import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
// import HomeFiveMain from './TeacherMain';

import favIcon from '../../assets/img/fav.png';
// import Logo from '../../assets/img/logo/logo3.png';
// import stickyLogo from '../../assets/img/logo/logo.png';
// import darkLogo from '../../assets/img/logo/logo-dark.png';
// import smallDeviceLogo from '../../assets/img/logo/logo-dark2.png'
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import TeacherMain from './TeacherMain';
import AbacusResearchMain from './AbacusResearchMain';

const AbacusResearch = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Research On Abacus Learning & Its Impact</title> 
				<meta name="keywords" content="Abacus, Research on Abacus, Abacus Instrument, Types of Abacus, Design of Abacus, Development Of Abacus, Abacus Math Program, Abacus Classes, Whole Brain Development, Abacus Training, Mastermind Abacus Development"/>
		<meta name="description" content="The speed & accuracy of Abacus learning became a subject of research. Find the details about 'Research On Abacus' and Mastermind Abacus development."/>
		
		
		
		<meta property="og:title" content="Research On Abacus Learning & Its Impact"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/about-abacus/research-on-abacus"/>
		<meta property="og:description" content="The speed & accuracy of Abacus learning became a subject of research. Find the details about 'Research On Abacus' and Mastermind Abacus development."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Research On Abacus Learning & Its Impact"/>
		<meta name="twitter:description" content="The speed & accuracy of Abacus learning became a subject of research. Find the details about 'Research On Abacus' and Mastermind Abacus development."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            {/* <HeaderStyleThree
                parentMenu='home'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                responsiveLogo={smallDeviceLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify"
                TopBarClass="topbar-area dark-parimary-bg"
            /> */}
            <HeaderStyleFour
            parentMenu='resources'
            TopBar='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={stickyLogo}
            // CanvasLogo={Logo}
            CanvasClass='right_menu_togle hidden-md'
            headerClass='full-width-header header-style2'
            TopBarClass='topbar-area dark-parimary-bg'
            emailAddress='support@website.com'
            phoneNumber='(+088) 589-8745'
            />
            <AbacusResearchMain />
            <FooterStyleTwo />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default AbacusResearch;