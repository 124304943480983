import React from 'react'
// import SectionTitle from '../../components/Common/SectionTitle'

const RredLineSection = () => {

  return (
    <div className='rs-banner redLine-style-5 '>
      {/* <div className='banner-content'>
        <div className="sub-title md-fs-5 sm-fs-5" style={{fontSize:"20px", backgroundColor:"#FF284B", paddingBlock:"15px", textAlign:"center", color:"white"}}> MASTERMIND ABACUS PROGRAM IMPROVES YOUR CHILD'S MENTAL MATH AND OVERALL ACADEMIC PERFORMANCE </div>
        </div> */}

      <div className=" sm-mb-30  text-center">
        {/* <SectionTitle
          sectionClass="sec-title"
          titleClass="title mb-0"
          title="OUR TRAINING HAS BENEFITTED STUDENTS ACROSS 17 COUNTRIES"
        /> */}
        <div className="sub-title md-fs-5 sm-fs-5" style={{fontSize:"20px", backgroundColor:"#FF284B", paddingBlock:"15px", textAlign:"center", color:"white"}}> Frequently Asked Questions </div>

      </div>
    </div>
  )
}

export default RredLineSection