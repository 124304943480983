import React from "react";
// import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
// import Tilty from 'react-tilty';
import SectionTitle from '../../components/Common/SectionTitle';

// import animateBall from '../../assets/img/about/image-center-circle.png';
// import mainImage from '../../assets/img/abacus/about/maxresdefault.jpg';


function RefundConent() {

    useEffect(() => {
        AOS.init();
    })

    return (
        <div id="rs-about" className="rs-about style1 pt-50 pb-50 md-pt-70 md-pb-70 back-color" >
            <div className="container">
                {/* <div className="row align-items-center">


                    <div className="col-lg-6 order-last">
                    
                        <SectionTitle
                            sectionClass="sec-title mb-6"
                            subtitleClass="sub-title primary"
                            // subtitle="About Us"
                            titleClass="title"
                            title={<>
                               ABOUT MASTERMIND ABACUS</>}
                            descClass="desc pr-36"
                            description={<>TWe are Mastermind Abacus, a Multinational Education Company based at Indore, India. We have our presence in 16 Countries. We share our educational concepts Globally through 2000-plus Associate Education Franchisee. We are a company driven by the educational needs of the next generation. We expertise in identifying basic learning problems in kids and accordingly develop programs to ease their learning process. We are one of the largest Abacus company with a global presence.</>}
                            animateName="fade-up"
                            animateDuration="1200"
                        />
                    </div>
                    <div className="col-lg-6 pr-30  md-mb-60">
                        <div className="img-part">
                            <Tilty perspective={1200} reverse={true}>
                                <img
                                    src={mainImage}
                                    alt="Main Image"
                                />
                            </Tilty>
                        </div>
                    </div>

                </div> */}

                <SectionTitle
                    sectionClass="sec-title mb-26"
                    subtitleClass="sub-title primary"
                    // subtitle="About Us"
                    titleClass="title"
                    title="CANCELLATION & REFUND POLICY"
                    descClass="desc pr-36"
                    description={<>As Of Now We Do Not Provide Any Option For Cancellation Of Products And/Or Services You Have Purchased Or Subscribed. Once A Product/Service Has Been Purchased By You, We Cannot Provide Any Refund, Either Totally Or Partially. We Suggest That First You Go Through The Demos Before You Subscribe To Or Purchase From MMTPL And As A Customer You Are Responsible For Understanding That No Refund Or Cancellation Is Possible Upon Any Subscription At Our Site.</>}
                    animateName="fade-up"
                    animateDuration="1200"
                />

            </div>
        </div>
    );
}

export default RefundConent;