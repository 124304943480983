import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import RSMobileMenu from './RSMobileMenu';
// import CanvasMenu from './CanvasMenu';
// import TopHeader from './TopBar';

// import darkLogo from '../../../assets/img/logo/dark-logo.png';
import abacusLogo from '../../../assets/img/mastermindabacus-logo.png'
import FranchiseManu from './FranchiseManu';
import FranchiseTopBar from './FranchiseTopBar';
import FranchiseMobile from './FranchiseMobile';


const HeaderStyleFive = (props) => {
	const { headerClass, headerFullWidth, parentMenu, secondParentMenu, activeMenu, headerNormalLogo, headerStickyLogo, mobileNormalLogo, TopBar, TopBarClass, emailAddress, phoneNumber, Location } = props;
	const [menuOpen, setMenuOpen] = useState(false)

	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<React.Fragment>
			<div className={headerClass ? headerClass : 'full-width-header home8-style4 main-home'}>
				<header id="rs-header" className='rs-header'>
					{
						TopBar ? <FranchiseTopBar topBarClass={TopBarClass} emailAddress={emailAddress} phoneNumber={phoneNumber} Location={Location} /> : ""
					}

					<div className={isVisible ? 'menu-area menu-sticky sticky' : 'menu-area menu-sticky'}>
						<div className={headerFullWidth ? 'container-fluid' : 'container'}>
							<div className="row y-middle">
								<div className="col-lg-2">
									<div className="logo-cat-wrap hidden-md">
										<div className="logo-part pr-90">
											<Link to="/">
												<img className="normal-logo"  src={headerNormalLogo ? abacusLogo : abacusLogo} alt="" />
												<img className="sticky-logo" src={headerStickyLogo ? abacusLogo : abacusLogo} alt="" />
											</Link>
										</div>
									
									</div>
								</div>
								<div className="col-lg-10 text-center">
									<div className="rs-menu-area">
										<div className="main-menu pr-60">
											<div className="mobile-menu md-display-block">
												<Link to="/" className="mobile-normal-logo">
													<img className="normal-logo" src={mobileNormalLogo ? abacusLogo : abacusLogo} alt="" />
												</Link>
												<Link to="/" className="mobile-sticky-logo">
													<img src={abacusLogo} alt="logo" />
												</Link>
												<a href="#" className="rs-menu-toggle" onClick={() => {
													setMenuOpen(!menuOpen)
												}}>
													<i className="fa fa-bars"></i>
												</a>

											</div>
											<nav className="rs-menu hidden-md">
												<ul className="nav-menu">
													<FranchiseManu
														parentMenu={parentMenu}
														secondParentMenu={secondParentMenu}
														activeMenu={activeMenu}
													/>
												</ul>
											</nav>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>

					<FranchiseMobile
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
						parentMenu={parentMenu}
						secondParentMenu={secondParentMenu}
					/>
					<div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
				</header>
				{/* <CanvasMenu
					canvasClass={CanvasClass ? CanvasClass : "right_menu_togle orange_color hidden-md"}
					canvasLogo={CanvasLogo ? CanvasLogo : darkLogo}
				/> */}
			</div>
		</React.Fragment>
	);
}

export default HeaderStyleFive;