import React from 'react';
// import { a } from 'react-router-dom';

const Relatedblogs = (props) => {
    const {Relatedblog} = props
    return (
        <>
            {Relatedblog.length < 0 ? 
        <div className="recent-posts-widget mb-50">
            <h3 className="widget-title">Related Blog</h3>
            { Relatedblog.map((item,index)=>(
                <div className="show-featured" key={index}>
                <div className="post-img">
                    <a href={`/blog_detail/${item.blog_url}`}>
                        <img
                            src={item.image_url}
                            alt="Mastermind Abacus"
                        />
                    </a>
                </div>
                <div className="post-desc">
                    <a href={`/blog_detail/${item.blog_url}`}>{item.subject}</a>
                    <span className="date">
                        {item.blog_date ? <i className="fa fa-calendar"></i> :null}                        
                         {item.blog_date}
                    </span>
                </div>
            </div>
             ))}
         
             
             </div>
             :null}
             
             </>
    )
}

export default Relatedblogs;