import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Tilty from 'react-tilty';
import AOS from 'aos';

import circle from '../../assets/img/banner/banner-circle.png';
import dots from '../../assets/img/banner/banner-dots.png';
import bgImg from '../../assets/img/abacus/NearClasses/abacus-classes-near-me.webp'
import { HomeModal } from '../Common/Modal/HomeModal';

const bgStyle = {
    backgroundImage: `url(${bgImg})`,
    'background-size': 'cover',
    'background-position': 'center',
    'height': '70vh',
    'display': 'grid',
    'align-items': 'center',
};




const BannerNearMe = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

    useEffect(() => {
        AOS.init();
    })

    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner style4" style={bgStyle}>
                <div className="container relative">
                    <div className="row relative">
                        <div className="col-lg-6">
                            <div className="banner-content pb-14">

                                <div className="desc black-color mb-35" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="600" style={{ marginTop: "-10px" }}>Skip long distance travels for <br/>
                                    learning Abacus
                                </div>
                                <div className="banner-title ">FIND <br/>
                                MASTERMIND ABACUS CLASSES <br/> IN YOUR VICINITY</div>

                                <div className="banner-btn z-index-1"  style={{ marginTop: "20px" }}>
                                    <Link className="readon2 banner-style" onClick={openModal}>RAISE AN INQUIRY</Link>
                                </div>
                            </div>
                            <div className="banner-circle-shape">
                                <Tilty>
                                    <img
                                        className="rotateme"
                                        src={circle}
                                        alt="Mastermind Abacus"
                                    />
                                </Tilty>
                            </div>
                        </div>
                    </div>
                    <div className="banner-dots-shape">
                        <Tilty>
                            <img
                                src={dots}
                                alt="Mastermind Abacus"
                            />
                        </Tilty>
                    </div>
                </div>
            </div>
            <HomeModal isOpen={isModalOpen} onClose={closeModal} status={'offline'}/>
        </React.Fragment>
    );
}

export default BannerNearMe;