
import img from "../../assets/images/Mastermind2.5ab5f0b7-min.png";
import img2 from "../../assets/images/Mastermind Group (3).png";
import 'react-phone-number-input/style.css';
import '../../assets/landingstyle/index.css';
import '../../assets/landingstyle/output.css';
import BookclassModal from './BookclassModal';
const Hero = () => {

    return (
        <>
            <div className='flex items-center justify-center'>
                {/* {isExploding && <ConfettiExplosion force={0.8} duration={3000} particleCount={400} width={1600} />} */}
            </div>
            <div className="flex md:flex-row flex-col md:items-center md:justify-center pt-0 mt-140 px-8 md:px-48 mb-4">
                <div className="md:w-1/2 w-full">
                    <div>
                        <div className=" absolute left-0 pt-4 md:top-[5.3rem] top-24">
                            <img src={img2} className="md:w-full w-1/2 " alt="Mastermind Group" />
                        </div>
                        <div className="pt-10 my-1 z-20 items-center text-center">
                            <div className="orange-color family font-extrabold md:text-5xl text-3xl"> LEARN ABACUS</div>
                            <span className='md:text-3xl text-xl'>
                                <div>Boost Mental Math Skills </div>              <div className='font-bold pt-2'>
                                    SPARK EARLY MATH GENIUS
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="md:w-full mt-8">  <img src={img} className="w-full" alt="Mastermind Logo" /></div>

                </div>
                <div className="ml-40 md-ml-0">
                    <BookclassModal />
                </div>
            </div>
        </>
    );
}

export default Hero;
