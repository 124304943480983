import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
// import Header from '../../components/Layout/Header';
// import Footer from '../../components/Layout/Footer/Footer';
// import SearchModal from '../../components/Layout/Header/SearchModal';
// import ScrollToTop from '../../components/Common/ScrollTop';
// import Newsletter from '../../components/Common/Newsletter';
import ContactMain from './ContactMain';

// Image
// import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';


const ContactThree = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* Header */}
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Best Abacus Training Centre Contact | Mastermind Abacus</title>
                <meta name="keywords" content="Best Abacus Training Centre, Abacus Trainers, Abacus Training, Best Abacus Franchise, Contact Best Abacus Franchise, Best Abacus Company, Best Abacus Company In India." />
                <meta name="description" content="Mastermind Abacus a leading Abacus Training Company; having global presence with 2000-plus centers across 16 countries. Contact us for details." />



                <meta property="og:title" content="Best Abacus Training Centre Contact | Mastermind Abacus" />
                <meta property="og:site_name" content="Mastermind Abacus" />
                <meta property="og:url" content="https://www.mastermindabacus.com/contact" />
                <meta property="og:description" content="Mastermind Abacus a leading Abacus Training Company; having global presence with 2000-plus centers across 16 countries. Contact us for details." />

                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@mastermindabac2" />
                <meta name="twitter:title" content="Best Abacus Training Centre Contact | Mastermind Abacus" />
                <meta name="twitter:description" content="Mastermind Abacus a leading Abacus Training Company; having global presence with 2000-plus centers across 16 countries. Contact us for details." />
                <meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png" />
            </Helmet>
            <HeaderStyleFour
                parentMenu='contact'
                TopBar='enable'
                headerNormalLogo={Logo}
                // headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            {/* Header */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            {/* Newsletter Section Start */}
            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}
            {/* Newsletter Section End */}

            {/* Footer */}
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* Footer */}

            {/* SearchModal */}
            {/* <SearchModal /> */}
            {/* SearchModal */}

            {/* scrolltop-start */}
            {/* <ScrollToTop
                scrollClassName="scrollup orange-color"
            /> */}
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default ContactThree;