import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
// import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
// import HomeSixMain from './HomeSixMain';

// import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo.png';
// import HistoryMain from './HistoryMain';
import InvestmentMain from './InvestmentMain';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';

const Investment = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Abacus Franchise cost | Mastermind</title> 


		<meta name="description" content="Mastermind Abacus Franchise opportunity at a low Abacus Franchise Cost and no royalty, online & offline class options. Apply now"/>
		<meta name="keywords" content="Abacus Franchise cost, Mastermind Abacus Franchise investment, Best Abacus Franchise, Abacus Franchise Fee, Low Investment Franchise, Low Franchisee Cost, Education Franchise, Afterschool Education Franchise, Online Abacus Franchise"/>
				 
				
		
		<meta property="og:title" content="Abacus Franchise cost | Mastermind"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/abacus-franchise/investment"/>
		<meta property="og:description" content="Mastermind Abacus Franchise opportunity at a low Abacus Franchise Cost and no royalty, online & offline class options. Apply now"/>
	
		<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
		
		<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Abacus Franchise cost | Mastermind"/>
		<meta name="twitter:description" content="Mastermind Abacus Franchise opportunity at a low Abacus Franchise Cost and no royalty, online & offline class options. Apply now"/>
		<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFive
                parentMenu='home'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            <InvestmentMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
        </React.Fragment>
    );
}


export default Investment;