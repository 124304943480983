import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
// import AboutImage from '../../assets/img/about/home5/about-main.png';
import AboutImage from '../../assets/img/abacus/watch_how_mastermind.jpg'
// import circleShape from '../../assets/img/about/home5/about-circle-bg.png';
// import smallCircleShape from '../../assets/img/about/home5/small-circle-shape.png';
// import deepShape from '../../assets/img/about/home5/about-deep-bg.png';

// import phnIcon from '../../assets/img/about/icon/1.png';
// import mailIcon from '../../assets/img/about/icon/2.png';
import ModalVideo from 'react-modal-video';

const TeacherBenefite = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    return (
        <div id="rs-about" className="rs-about benefitSection-teacher pt-100 pb-100 md-pt-40 md-pb-40" >
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='Pcccj1L_oME' onClose={() => { openModal(); }} />
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-6">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-20 text-black "
                                subtitleClass="sub-title"
                                // subtitle="Personal & Professional Coach"
                                titleClass="title family mb-14 sm-mb-6"
                                title={<> <span className='title-change'> BENEFITS </span>OF ABACUS</>}
                                descClass="desc mb-26"
                                description="Mastermind Abacus training develops dynamic number sense in children. The specific game-based technique keeps kids engaged. It removes the fear of math right from childhood. Other than Mental Math, it also improves academic performance with the development of crucial life skills such as:"
                                secondDescClass="desc"
                                  
                            // secondDescription="When nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business"
                            />
                            {/* Section Title End */}
                            <ul className="d-flex flex-wrap contact-part justify-content-center"   >
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right" ></i>Self-Confidence</span>
                                    </div>
                                </li>
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right" ></i> Ability To Recall</span>
                                    </div>
                                </li>
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right" ></i> Concentration</span>
                                    </div>
                                </li>
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right" ></i> Visualization</span>
                                    </div>
                                </li>
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right" ></i> Photographic Memory</span>
                                    </div>
                                </li>
                                <li>

                                    <div className="desc">
                                        <span> <i class="fa-solid fa-circle-chevron-right"></i> Listening Skill</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 md-mb-0 md-mt-30">
                        <div className="img-part" >
                            <img className="about-main" onClick={() => { openModal(); }} src={AboutImage} alt="About Image" />
                            {/* <img className="circle-bg shape" src={circleShape} alt="About Image" />
                            <img className="small-circle shape animated pulse infinite" src={smallCircleShape} alt="About Image" />
                            <img className="deep-bg shape" src={deepShape} alt="About Image" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeacherBenefite;