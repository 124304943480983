import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
// import SearchModal from '../../components/Layout/Header/SearchModal';
// import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import FrnachiseMain from './FeanchiseMain';
import HeaderStyleFive from '../../components/Layout/Header/HeaderStyleFive';

const Franchise = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>Abacus Franchise | Global Business Opportunity</title> 


		<meta name="description" content="To become a part of the Mastermind Abacus Franchisee Business, inquire now to know the  process details."/>
		<meta name="keywords" content="Abacus Franchisee, Abacus Franchise, Abacus Franchisee Opportunity, Franchisee Business, Mastermind Abacus, Franchise Opportunity, Business Opportunity, Franchisee Business Opportunity, Mastermind Abacus Franchise, How to start abacus center, How to start abacus classes, Education Franchisee Opportunity, Online Abacus Franchisee, abacus classes franchise"/>
				
		
		<meta property="og:title" content="Abacus Franchise | Global Business Opportunity"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/abacus-franchise"/>
		<meta property="og:description" content="To become a part of the Mastermind Abacus Franchisee Business, inquire now to know the  process details."/>
	
		<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
		
		<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="Abacus Franchise | Global Business Opportunity"/>
		<meta name="twitter:description" content="To become a part of the Mastermind Abacus Franchisee Business, inquire now to know the  process details."/>
		<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            {/* <HeaderStyleThree
                parentMenu='home'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                responsiveLogo={smallDeviceLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify"
                TopBarClass="topbar-area dark-parimary-bg"
            /> */}
            <HeaderStyleFive
            parentMenu='home'
            TopBar='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={stickyLogo}
            // CanvasLogo={Logo}
            // CanvasClass='right_menu_togle hidden-md'
            headerClass='full-width-header header-style2'
            TopBarClass='topbar-area dark-parimary-bg'
            emailAddress='support@website.com'
            phoneNumber='(+088) 589-8745'
            />
            <FrnachiseMain />
            <FooterStyleTwo />
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default Franchise;