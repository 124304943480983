import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HeaderStyleFour from '../../components/Layout/Header/HeaderStyleFour';
import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import AbacusShcoolMain from './AbacusShcoolMain';
import ScrollToTop from '../../components/Common/ScrollTop';

const AbacusShcool = () => {
    return (
        <React.Fragment>
            <Helmet>
                {/* <link rel="icon" href={favIcon} /> */}
                <title>ABACUS FOR SCHOOLS | MASTERMIND</title> 
				<meta name="keywords" content="Abacus Franchise, Abacus Franchisee, Abacus Franchisor Abacus, Abacus Books, Abacus Worksheets, Abacus Practice Sheet, Abacus Training, Abacus Online Training, Franchisee Opportunity"/>
		<meta name="description" content="A budget-friendly abacus program exclusively designed for schools, with unlimited training support by mastermind abacus."/>
		
		
		
		<meta property="og:title" content="ABACUS FOR SCHOOLS | MASTERMIND"/>
		<meta property="og:site_name" content="Mastermind Abacus"/>
		<meta property="og:url" content="https://www.mastermindabacus.com/abacus-for-schools"/>
		<meta property="og:description" content="A budget-friendly abacus program exclusively designed for schools, with unlimited training support by mastermind abacus."/>
	
					<meta property="og:type" content="article"/>
		<meta property="og:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
					<meta name="twitter:card" content="summary_large_image"/>
		<meta name="twitter:site" content="@mastermindabac2"/>
		<meta name="twitter:title" content="ABACUS FOR SCHOOLS | MASTERMIND"/>
		<meta name="twitter:description" content="A budget-friendly abacus program exclusively designed for schools, with unlimited training support by mastermind abacus."/>
					<meta name="twitter:image" content="https://www.mastermindabacus.com/assets/new/images/mastermindabacus-logo.png"/>
            </Helmet>
            <OffWrap />
            <HeaderStyleFour
                parentMenu='abacus-class'
                TopBar='enable'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={Logo}
                CanvasClass='right_menu_togle hidden-md'
                headerClass='full-width-header header-style2'
                TopBarClass='topbar-area dark-parimary-bg'
                emailAddress='support@website.com'
                phoneNumber='(+088) 589-8745'

            />
            <AbacusShcoolMain />
            <FooterStyleTwo
                footerTopClass='footer-top no-gap'
            />
            {/* <SearchModal /> */}

            <ScrollToTop status={'FRANCHISE'} name={'INQUIRY NOW'}  message={"SCHOOL INQUIRY"}  id={'25'} />
        </React.Fragment>
    );
}


export default AbacusShcool;